import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';



@Injectable()
export class FarmerService {

  farmerURL = this.appConfigService.getUrl(this.appConfigService.FARMER);

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

  GetFarmerMaster(): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/master`);
  }

  GetAllFarmers(): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getall/byproject`);
  }

  GetFarmer(farmerId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getFarmerById/${farmerId}`);
  }

  deleteFarmer(farmerId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/delete/${farmerId}`);
  }

  GetFarmers(): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/get/allFarmers`);
  }


  uploadFarmerImage(formData: any): Observable<any> {
    return this.http.post<any>(`${this.farmerURL}/uploadFarmerImage`, formData);
  }

  SaveFarmer(farmer: any): Observable<any> {
    return this.http.put(`${this.farmerURL}/updateFarmer/${farmer.id}`, farmer );
  }

  updateFarmerRegData(farmer: any): Observable<any> {
    return this.http.put(`${this.farmerURL}/updateFarmerRegData`, farmer );
  }

  registerFarmer(farmer: any): Observable<any> {
    return this.http.post(`${this.farmerURL}/register`, farmer);
  }

  SaveAgro(agro: any): Observable<any> {
    return this.http.post(`${this.farmerURL}/master/`, agro );
  }

  /** DR-242 - Farmer Husbandry */
  SaveHusbandry(farmer: any): Observable<any> {
    return farmer.id &&  farmer.id > 0 ? this.http.put(`${this.farmerURL}/husbandry/${farmer.id}`, farmer )
    : this.http.post(`${this.farmerURL}/husbandry`, farmer );
  }

  emailIdCardBulk(farmerIdArray): Observable<any> {
    return this.http.post<any>( `${this.farmerURL}/emailIdCardBulk`, farmerIdArray);
  }


  SaveNotes(farmer: any): Observable<any> {
    return  this.http.post(`${this.farmerURL}/notes`, farmer );
  }

  getFarmerManagementTabs(): Observable<any> {
    return this.http.get<any>(`${this.farmerURL}/farmermanagement/tabs`);
  }

  getFarmRegQuestions(): Observable<any> {
    return this.http.get<any>(`${this.farmerURL}/getFarmRegQuestions`);
  }

  GetFarmersByProjectIdAllData(projectId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getAllFarmersForProjectId/${projectId}`);
  }
  exportData(aId: string, farmerdetail: any) {
    return this.http.post<any>(`${this.farmerURL}/exportFarmerData`, { id: aId,farmerId: farmerdetail});
  }

  getFarmersByProjectBasicData(projectId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getFarmersByProjectBasicData/${projectId}`);
  }

  getFarmerBasicDataById(farmerId): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getFarmerBasicDataById/${farmerId}`);
  }
  
  farmerAvailableNotAvailable(farmerId: number, available: boolean, projectId:number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/farmerAvailableNotAvailable/${farmerId}/${available}/${projectId}`);
  }

  getFarmerAvailibility(projectId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getFarmerAvailibility/${projectId}`);
  }

  saveFarmCoordinates(data: any): Observable<any> {
    return this.http.post<any>(`${this.farmerURL}/saveFarmCoordinates`, data);
  }
  getFarmCoordinates(farmerId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getFarmCoordinates/${farmerId}`);
  }
  getEstimatedPotentialValue(projectId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getEstimatedPotentialValue/${projectId}`);
  }

  deleteFarm(farmId: number): Observable<any> {
    return this.http.delete<any>( `${this.farmerURL}/deleteFarm/${farmId}`);
  }

  uploadFarmImage(formData: any): Observable<any> {
    return this.http.post<any>(`${this.farmerURL}/uploadFarmImage`, formData);
  }
  discontinueFarmer(projectId: number, farmerId: number, status: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/updateIsDiscontiunedFlag/${projectId}/${farmerId}/${status}`);
  }

  getDiscontinuedFarmerSurveyMapping(projectId: number): Observable<any> {
    return this.http.get<any>( `${this.farmerURL}/getDiscontinuedFarmerSurveyMapping/${projectId}`);
  }


}
