import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { TrainingService } from '../../services/training.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-training-management',
  templateUrl: './training-management.component.html',
  styleUrls: ['./training-management.component.css']
})
export class TrainingManagementComponent implements OnInit {
  projectId;
  project;
  projects;
  searchText = '';
  searchTextCerti = '';
  page = 1;
  itemsPerPage = 10;
  public previousPaginationLabel;
  public nextPaginationLabel;
  trainings:any;
  certifications:any;
  currentUser = this.userService.getCurrentUser();
  public selectTypeModal: BsModalRef;
  confirmTypeModal = true;
  selectedType;
  isAscending = true;
  isAscendingCerti = true;
  isAscendingName = true;
  isAscendingType = true;
  isAscendingTrainerName = true;
  isAscendingCertifiedBy = true;
  isAscendingDueDate = true;
  isAscendingTrainingDate = true;
  isAscendingNameCerti = true;
  isAscendingTrainingDateCerti = true;
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canExportToExcel = false;
  UPM;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly userService: UserService,
    private readonly modalService: BsModalService,
    private readonly projectService: ProjectService,
    private readonly trainingService: TrainingService,
    private readonly translate: TranslateService,
  ) { }

  async ngOnInit() {
    this.spinner.show();
    this.projectId = +this.route.snapshot.params['id'];
    const request = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.projectService.getProjectDashboardData(this.projectId).toPromise()
    ]);
    this.project = {...request[0].data[0], ...request[1].data[0]};
    if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
      this.UPM = this.project.ProjectMapping.find(item => item.users_id === this.currentUser.id);
    }

    if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
      this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
    }

    if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
      this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
    }

    if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
      const canExportToExcel = this.usersServiceComponentMappings.filter(item => (
        item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP" && item.user_type_id === this.currentUser.role.id)
      );
      if (canExportToExcel && canExportToExcel.length > 0) {
        this.canExportToExcel = true;
      }
    }
    else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
      const canExportToExcel = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP"));
      if (canExportToExcel && canExportToExcel.length > 0) {
        this.canExportToExcel = true;
      }
    }
    this.getTrainingCertifcationList();
    
  }
  async getTrainingCertifcationList(){    
    this.spinner.show();
    const request = await Promise.all([
      this.trainingService.get_training_recordings(this.projectId).toPromise()
    ]);
    if (request[0]['msg'] === 'success') {
      this.trainings = request[0]['data'].filter(item => item.is_training);
      this.certifications = request[0]['data'].filter(item => !item.is_training);
    }
    // console.log('trainings', this.trainings);
    // for(const item of this.certifications){
    //   if(item.certified_by == 1) {
    //     item.certified_by = "Firmenich";
    //   } else if(item.certified_by == 2) {
    //     item.certified_by = this.project.Supplier.name;
    //   } else {
    //     item.certified_by = '';
    //   }
    // }
    this.spinner.hide();
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  addTraingCertificate(template){
    this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  editTrainingCertification(id, t){
    let type;
    if(t == "Training"){
      type = "training";
    } else {
      type = "certification";
    }
    const url = `/training-management/edit/${type}/${this.projectId}/${id}`;
    this.router.navigate([url]);
  }

  viewTrainingCertification(id, t){
    let type;
    if(t == "Training"){
      type = "training";
    } else {
      type = "certification";
    }
    const url = `/training-management/edit/${type}/${this.projectId}/${id}`;
    this.router.navigate([url], { queryParams: { isView : true } });
  }

  submitType(){
    this.exportToExcel(this.selectedType)
    this.closeTypePopup();
  }

  closeTypePopup(fromUI?){
    if(fromUI){      
      if(this.selectedType){
        this.confirmTypeModal = false;
      } else if(this.selectTypeModal) {
        this.selectTypeModal.hide();
        this.selectedType = undefined;
        this.confirmTypeModal = true;
      }
    } else if(this.selectTypeModal) {
      this.confirmTypeModal = true;
      this.selectTypeModal.hide();
      this.selectedType = undefined;
    }
  }

  confirmModalFn(decision){
    if(decision == "Yes"){
      this.closeTypePopup();
      this.confirmTypeModal = true;
    } else {
      this.confirmTypeModal = true;
    }
  }

  exportToExcel(type) : void {
    this.spinner.show();
    this.trainingService.trainingManagementExportToExcel(this.projectId, type).subscribe(result => {
      //console.log('result', result);
      let wb = XLSX.utils.book_new();
      let wsBooks = XLSX.utils.json_to_sheet(result.message);
      const wscols = [
        {wpx: 200}, // "pixels
        {wpx: 130},
        {wpx: 130},
        {wpx: 200}
      ];
      wsBooks['!cols'] = wscols;
      const sheetName = `Sheet 1`
      XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);  
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      let linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const today1 = dd + '-' + mm + '-' + yyyy;
      this.translate.get('training_management').subscribe((val) => {
        linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
        linkdownload.remove();
      }, err => console.log('err', err));
      // var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
      // const blob = new Blob([result], {type: contentType});
      // const linkdownload = document.createElement('a');
      // linkdownload.href = URL.createObjectURL(blob);
      // var today = new Date();
      // var dd = String(today.getDate()).padStart(2, '0');
      // var mm = String(today.getMonth() + 1).padStart(2, '0');
      // var yyyy = today.getFullYear();
      // var today1 = `${dd}-${mm}-${yyyy}`;
      // this.translate.get('training_management').subscribe((val) => {
      //   linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
      //   linkdownload.style.display = 'none';
      //   document.body.appendChild(linkdownload);
      //   linkdownload.click();
      // }, err => console.log('err', err));
      this.spinner.hide();
    }, err=> {
      console.log('err', err);
      this.spinner.hide();
    })
  }

  getGridFilters() {
    const gridColums = ['training_certification_name','trainer_name', 'training_date'];
    
    const finalObj = {};
    if (this.trainings === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  getGridFiltersCerti() {
    const gridColums = ['training_certification_name', 'certified_by', 'certification_date'];
    
    const finalObj = {};
    if (this.certifications === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchTextCerti;
      });
    }
    return finalObj;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeTypePopup();    
  }

  sortBy(sortOn: string) {
    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    this.isAscendingName = sortBy !== 'training_certification_name';
    this.isAscendingTrainerName = sortBy !== 'trainer_name';
    this.isAscendingTrainingDate = sortBy !== 'training_date';
    // Sort of new fields added
    if (sortBy === 'training_certification_name' || sortBy === 'trainer_name') {
      this.trainings.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'training_date') {
      this.trainings.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;

    this.isAscendingName = sortBy === 'training_certification_name';
    this.isAscendingTrainerName = sortBy === 'trainer_name';
    this.isAscendingTrainingDate = sortBy === 'training_date';
    // Sort of new fields added
    if (sortBy === 'training_certification_name' || sortBy === 'trainer_name') {
      this.trainings.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'training_date') {
      this.trainings.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }
  }

  sortByCerti(sortOn: string) {
    this.isAscendingCerti ? this.ascendicCerti(sortOn) : this.descendicCerti(sortOn);
  }

  ascendicCerti(sortBy: string) {
    this.isAscendingCerti = false;

    this.isAscendingNameCerti = sortBy !== 'training_certification_name';
    this.isAscendingCertifiedBy = sortBy !== 'certified_by';
    this.isAscendingTrainingDateCerti = sortBy !== 'certification_date';
    // Sort of new fields added
    if (sortBy === 'training_certification_name' || sortBy === 'certified_by') {
      this.certifications.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'certification_date') {
      this.certifications.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendicCerti(sortBy: string) {
    this.isAscendingCerti = true;

    this.isAscendingNameCerti = sortBy === 'training_certification_name';
    this.isAscendingCertifiedBy = sortBy === 'certified_by';
    this.isAscendingTrainingDateCerti = sortBy === 'certification_date';
    // Sort of new fields added
    if (sortBy === 'training_certification_name' || sortBy === 'certified_by') {
      this.certifications.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'certification_date') {
      this.certifications.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }
  }

}
