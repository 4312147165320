import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { VisitService } from '../../services/visits.service';
import { FarmerService } from '../../services/farmer.service';
import { TrainingService } from '../../services/training.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import Compressor from 'compressorjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-visit-management',
  templateUrl: './visit-management.component.html',
  styleUrls: ['./visit-management.component.css']
})
export class VisitManagementComponent implements OnInit {
  projectId;
  project;
  projects;
  searchText = '';
  searchTextCR = '';
  page = 1;
  itemsPerPage = 10;
  public previousPaginationLabel;
  public nextPaginationLabel;
  trainings:any;
  visits;
  currentUser = this.userService.getCurrentUser();
  public addVisitModal: BsModalRef;
  confirmTypeModal = true;
  selectedType;
  isAscending = true;
  isAscendingName = true;
  isAscendingType = true;
  isAscendingTrainerName = true;
  isAscendingTypeCR = true;
  isAscendingTrainerNameCR = true;
  isAscendingDueDate = true;
  isAscendingDueDateCR = true;
  isAscendingTrainingDate = true;
  isAscendingCount = true;
  isAscendingCR = true;
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canExportToExcel = false;
  UPM;
  formIsEdited = false;
  public mappingAddObject ={
    "visitType":0,
    "description":'',
    "villageName":0,
    "agent":0,
    "duedate":null,
    "purpose":0,
    "uploadedVisitFilesOld" : []
  }
  disableAddButton = true;
  isEdit = false;  
  public isDueDateOpen = false;
  public dateFormat = 'DD-MMM-YYYY';
  allTrainers;
  allVillages;
  allVisitTypes;
  currentVisitID;
  maxDate = new Date();
  addVisit = false;
  farmers = [];
  selectedFarmers = [];
  allFarmers = [];
  searchTextFarmers;
  farmersListPage = 1;
  tooltipText;  
  selectDeselectLabels;
  isFarmVisitSelected = false;
  farmVisits = [];
  CRs = [];
  public selectTypeModal: BsModalRef;  
  allVisitPurposes;
  isDesktopDevice;
  uploadedVisitFiles = [];
  fileToDelete;
  public fileDeletePopup: BsModalRef;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly userService: UserService,
    private readonly modalService: BsModalService,
    private readonly projectService: ProjectService,
    private readonly visitService: VisitService,
    private readonly farmerService: FarmerService,
    private readonly translate: TranslateService,
    private readonly trainingService: TrainingService,
    private readonly commonService: CommonService,
    private readonly deviceService: DeviceDetectorService,
    public toastrService: ToastrService, 
  ) {    
    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });
   }

  async ngOnInit() {
    this.spinner.show();
    this.projectId = +this.route.snapshot.params['id'];
    const request = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.projectService.getProjectDashboardData(this.projectId).toPromise()
    ]);
    this.project = {...request[0].data[0], ...request[1].data[0]};
    if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
      this.UPM = this.project.ProjectMapping.find(item => item.users_id === this.currentUser.id);
    }

    if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
      this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
    }

    if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
      this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
    }

    if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
      const canExportToExcel = this.usersServiceComponentMappings.filter(item => (
        item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP" && item.user_type_id === this.currentUser.role.id)
      );
      if (canExportToExcel && canExportToExcel.length > 0) {
        this.canExportToExcel = true;
      }
    }
    else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
      const canExportToExcel = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP"));
      if (canExportToExcel && canExportToExcel.length > 0) {
        this.canExportToExcel = true;
      }
    }
    
    this.getAllVisits();
    this.getAllPreRequisites();    
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  async getAllVisits(){    
    this.spinner.show();
    const request = await Promise.all([
      this.visitService.getAllVisits(this.projectId).toPromise(),
      this.visitService.getAllVisitTypes().toPromise(),
      this.visitService.getAllVisitPurposes().toPromise(),
    ]);
    if (request[0]['msg'] === 'success') {
      this.visits = request[0]['data'];
    }
    if (request[1]['msg'] === 'success') {
      this.allVisitTypes = request[1]['data'];
    }
    if (request[2]['msg'] === 'success') {
      this.allVisitPurposes = request[2]['data'];
    }

    for(const item of this.visits){
      item.visit_type = item.MultiValueMaster.visit_type;
      item.village_name = item.region_villages.village_name;
    }
    this.separateVisitTypes();
    this.spinner.hide();
  }

  async getAllPreRequisites(){this.spinner.show();
    const request = await Promise.all([
      this.trainingService.get_all_trainer(this.projectId).toPromise(),
      this.visitService.getRegionVillages(this.projectId).toPromise(),
      this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise()
    ]);
    if (request[0]['msg'] === 'success') {
      this.allTrainers = request[0]['data'];
    }
    if (request[1]['msg'] === 'success') {
      this.allVillages = request[1]['data'];
    }
    if (request[2]['msg'] === 'success') {
      this.farmers = request[2]['data'].filter(item => !item.is_discontinued);
      for(const item of this.farmers){
        item.village_name = item.region_village.village_name
      }
    }
    this.spinner.hide();
  }

  separateVisitTypes(){    
    this.farmVisits = [];  
    this.CRs = [];
    for(const item of this.visits){
      const type = this.allVisitTypes.find(item2 => item2.id == item.visit_type_id);
      if(type){
        if(type.value == 'Farm Visit'){
          this.farmVisits.push(item);
        } else {
          this.CRs.push(item);
        }
      }
    }
    for(const item of this.farmVisits){
      item.farmer_count = item.farmer_ids.length;
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  addNewVisit(template){
    if(this.currentUser.role.id > 3){
      this.mappingAddObject.agent = this.currentUser.id;
    }
    this.resetMappingObject();
    this.searchTextFarmers = ""
    this.addVisit = true;
    this.uploadedVisitFiles = [];
  }

  showAddButton(){
      if(this.mappingAddObject.visitType != 0 && this.mappingAddObject.villageName != 0 && this.mappingAddObject.agent != 0 && this.mappingAddObject.duedate != null){
        this.disableAddButton = false;
      } else {
        this.disableAddButton = true;
      }
  }

  saveVisit(){    
    this.spinner.show();
    const farmersSelected = [];
    for(const item of this.selectedFarmers){
      const obj = {
        "farmer_id": item,
        "is_visited": 1
      }
      farmersSelected.push(obj);
    }
    const date = new Date(this.mappingAddObject.duedate);
    const visitDate = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

    const documentData = this.uploadedVisitFiles;
    
    const requestParams = {
      "visit_type_id":this.mappingAddObject.visitType,
      "agent_id":this.mappingAddObject.agent,
      "description":this.mappingAddObject.description,
      "village_area_id":this.mappingAddObject.villageName,
      "due_date":visitDate,
      "project_id":this.projectId,
      "visit_purpose": this.mappingAddObject.purpose,
      "visit_recordings_farmer_dtl":farmersSelected,
      "visit_recordings_documents_dtl": documentData
    }

    
    this.visitService.saveUpdateVisit(requestParams, this.isEdit, this.projectId).subscribe(result => {
      if (result['msg'] === 'success') {
        this.commonService.showToast('success', 'visit_created_successfully', {});       
        
        this.addVisit = false;
        this.getAllVisits();     
        this.resetMappingObject();    
        this.uploadedVisitFiles = [];
        this.spinner.hide();
      }
    })
  }

  async updateVisit(){    
    this.spinner.show();
    const farmersSelected = [];
    const curMapping = this.visits.find(item => item.id === this.currentVisitID);
    let dtlArray = [];
    if(curMapping){
      dtlArray = curMapping.visit_recordings_farmer_dtl;
    }
    for(const item of this.allFarmers){
      const exist = dtlArray.find(item2 => item2.farmer_id == item);
      console.log(exist)
      if(exist){
        const obj = {
          "id":exist.id,
          "farmer_id": item,
          "is_visited" : this.selectedFarmers.indexOf(item) > -1 ? 1 : 0
        }
        farmersSelected.push(obj);
      } else if(this.selectedFarmers.indexOf(item) > -1) {
        const obj = {
          "farmer_id": item,
          "is_visited" : 1
        }
        farmersSelected.push(obj);
      }
    }
    const date = new Date(this.mappingAddObject.duedate);
    const visitDate = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    const documentData = this.mappingAddObject.uploadedVisitFilesOld.concat(this.uploadedVisitFiles);
    const requestParams = {
      "id":this.currentVisitID,
      "visit_type_id":this.mappingAddObject.visitType,
      "agent_id":this.mappingAddObject.agent,
      "description":this.mappingAddObject.description,
      "village_area_id":this.mappingAddObject.villageName,
      "due_date":visitDate,
      "project_id":this.projectId,
      "visit_purpose": this.mappingAddObject.purpose,
      "visit_recordings_farmer_dtl":farmersSelected,
      "visit_recordings_documents_dtl": documentData
    } 
    await this.visitService.saveUpdateVisit(requestParams, this.isEdit, this.projectId).subscribe(result => {
      if (result['msg'] === 'success') {
        this.commonService.showToast('success', 'visit_updated_successfully', {});
        this.addVisit = false;
        this.getAllVisits();       
        this.resetMappingObject();
        this.uploadedVisitFiles = [];
        this.spinner.hide();
      }
    })
  }

  editVisit(id, type){
    this.isEdit = true;
    this.currentVisitID = id;
    const curMapping = this.visits.find(item => item.id === id);
    if(curMapping){
      console.log("curMapping:", curMapping);
      this.mappingAddObject.visitType = curMapping.visit_type_id;
      this.mappingAddObject.villageName = curMapping.region_villages.id;
      this.mappingAddObject.description = curMapping.description;
      this.mappingAddObject.agent = curMapping.agent_id;
      this.mappingAddObject.duedate = curMapping.due_date? new Date(curMapping.due_date) : null;      
      this.mappingAddObject.purpose = 0;
      this.mappingAddObject.uploadedVisitFilesOld = curMapping.visit_recordings_documents_dtl;
    }
    if(type == 'Farm Visit'){
      this.isFarmVisitSelected = true;
      this.mappingAddObject.purpose = parseInt(curMapping.visit_purpose);
      this.selectedFarmers = curMapping.farmer_ids;
      this.allFarmers = curMapping.farmer_ids;
      this.searchTextFarmers = curMapping.village_name;
    } else {
      this.isFarmVisitSelected = false;      
    }
    this.addVisit = true;   
    
    console.log("this.mappingAddObject:", this.mappingAddObject)
  }

  closePopUp(template, form?: any ){
    if(!this.isEdit){
      if(this.mappingAddObject.visitType != 0 || this.mappingAddObject.villageName != 0 || this.mappingAddObject.agent != 0){
        
        this.addVisitModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
      } else {
        this.resetMappingObject();
        this.addVisit = false;
      }
    } else {
      if (form.dirty) {
        this.addVisitModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
        
      } else {
        this.resetMappingObject();
        this.addVisit = false;
        
      }
    }
  }
  closePopUpYes(){
    this.resetMappingObject()
    this.addVisitModal.hide();
    this.addVisit = false;
  }
  closePopUpNo(){
    this.addVisitModal.hide();
  }

  closePage(template, form?: any){
    if (form.dirty) {
      this.addVisitModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
    } else {
      this.resetMappingObject();
      this.addVisit = false;
    }
  }

  resetMappingObject(){    
    this.mappingAddObject.visitType = 0;
    this.mappingAddObject.villageName = 0;
    this.mappingAddObject.description = null;
    this.mappingAddObject.agent = 0;
    this.mappingAddObject.duedate = null;
    this.mappingAddObject.purpose = 0;
    this.mappingAddObject.uploadedVisitFilesOld = [];
    this.disableAddButton = true;
    this.isFarmVisitSelected = false;
    this.selectedFarmers = [];
    this.isEdit = false;
  }

  exportAllData(template){
    this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });    
  }

  submitType(){
    this.exportToExcel(this.selectedType);
    this.closeTypePopup();
  }

  closeTypePopup(fromUI?){
    if(fromUI){      
      if(this.selectedType){
        this.confirmTypeModal = false;
      } else if(this.selectTypeModal) {
        this.selectTypeModal.hide();
        this.selectedType = undefined;
        this.confirmTypeModal = true;
      }
    } else if(this.selectTypeModal) {
      this.confirmTypeModal = true;
      this.selectTypeModal.hide();
      this.selectedType = undefined;
    }
  }

  confirmTypeModalFn(decision){
    if(decision == "Yes"){
      this.closeTypePopup();
      this.confirmTypeModal = true;
    } else {
      this.confirmTypeModal = true;
    }
  }

  exportToExcel(type) : void {
    this.spinner.show();
    this.visitService.visitManagementExportToExcel(this.projectId, type).subscribe(result => {
      //console.log('result', result);
      let wb = XLSX.utils.book_new();
      let wsBooks = XLSX.utils.json_to_sheet(result.message);
      const wscols = [
        {wpx: 130}, // "pixels
        {wpx: 130},
        {wpx: 130},
        {wpx: 200},
        {wpx: 130},
        {wpx: 130},
        {wpx: 130}
      ];
      wsBooks['!cols'] = wscols;
      const sheetName = `Sheet 1`
      XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);  
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      let linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const today1 = dd + '-' + mm + '-' + yyyy;
      this.translate.get('visit_management').subscribe((val) => {
        linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
        linkdownload.remove();
      }, err => console.log('err', err));
      // var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
      // const blob = new Blob([result], {type: contentType});
      // const linkdownload = document.createElement('a');
      // linkdownload.href = URL.createObjectURL(blob);
      // var today = new Date();
      // var dd = String(today.getDate()).padStart(2, '0');
      // var mm = String(today.getMonth() + 1).padStart(2, '0');
      // var yyyy = today.getFullYear();
      // var today1 = `${dd}-${mm}-${yyyy}`;
      // this.translate.get('visit_management').subscribe((val) => {
      //   linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
      //   linkdownload.style.display = 'none';
      //   document.body.appendChild(linkdownload);
      //   linkdownload.click();
      // }, err => console.log('err', err));
        this.spinner.hide();
    }, err=> {
      console.log('err', err);
      this.spinner.hide();
    })   
  }

  getGridFilters() {
    const gridColums = ['village_name', 'farmer_count', 'agent_name', 'due_date'];
    
    const finalObj = {};
    if (this.farmVisits === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  getGridFiltersCR() {
    const gridColums = ['village_name', 'agent_name', 'due_date'];
    
    const finalObj = {};
    if (this.CRs === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchTextCR;
      });
    }
    return finalObj;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeTypePopup(true)  
  }

  sortBy(sortOn: string) {
    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
  }

  ascendic(sortBy: string) {
    this.isAscending = false;
    this.isAscendingType = sortBy !== 'village_name';
    this.isAscendingTrainerName = sortBy !== 'agent_name';
    this.isAscendingDueDate = sortBy !== 'due_date';
    this.isAscendingCount = sortBy !== 'farmer_count';
    // Sort of new fields added
    if (sortBy === 'village_name' || sortBy === 'agent_name') {
      this.farmVisits.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'due_date' || sortBy === 'farmer_count') {
      this.farmVisits.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;
    this.isAscendingType = sortBy === 'village_name';
    this.isAscendingTrainerName = sortBy === 'agent_name';
    this.isAscendingDueDate = sortBy === 'due_date';
    this.isAscendingCount = sortBy === 'farmer_count';
    // Sort of new fields added
    if (sortBy === 'village_name' || sortBy === 'agent_name') {
      this.farmVisits.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'due_date' || sortBy === 'farmer_count') {
      this.farmVisits.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }
  }

  sortByCR(sortOn: string) {
    this.isAscendingCR ? this.ascendicCR(sortOn) : this.descendicCR(sortOn);
  }

  ascendicCR(sortBy: string) {
    this.isAscendingCR = false;
    this.isAscendingTypeCR = sortBy !== 'village_name';
    this.isAscendingTrainerNameCR = sortBy !== 'agent_name';
    this.isAscendingDueDateCR = sortBy !== 'due_date';
    // Sort of new fields added
    if (sortBy === 'village_name' || sortBy === 'agent_name') {
      this.CRs.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'due_date') {
      this.CRs.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendicCR(sortBy: string) {
    this.isAscendingCR = true;
    this.isAscendingTypeCR = sortBy === 'village_name';
    this.isAscendingTrainerNameCR = sortBy === 'agent_name';
    this.isAscendingDueDateCR = sortBy === 'due_date';
    // Sort of new fields added
    if (sortBy === 'village_name' || sortBy === 'agent_name') {
      this.CRs.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    } else if (sortBy === 'due_date') {
      this.CRs.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }
  }

  trackByTrainer(index, dataItem) {
    return dataItem.groupName;
  }

  getGridFiltersFarmers() {
    const gridColums = ['farmer_code', 'name', 'tel_number', 'official_id', 'age', 'village_name','village_collectors', 'cr_date'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchTextFarmers;
      });
    }
    return finalObj;
  }

  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length === this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (const val of this.farmers) {
        this.selectedFarmers.push(val.id);
        if (this.allFarmers.indexOf(val.id) < 0) {
          this.allFarmers.push(val.id);
        }
      }
    }
    this.showAddButton();
  }

   // Toggle a particular farmer's selection status
   toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item !== id);
    }
    else {
      this.selectedFarmers.push(id);
      if (this.allFarmers.indexOf(id) < 0) {
        this.allFarmers.push(id);
      }
    }
    this.showAddButton();
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length === this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }
  visitTypeSelected(){
    const visitId = this.mappingAddObject.visitType
    if(visitId != 0){
      const selectedVisitType = this.allVisitTypes.filter(item => item.id == visitId);
      if(selectedVisitType.length > 0){
        if(selectedVisitType[0].value == 'Farm Visit'){
          this.isFarmVisitSelected = true;
        } else {
          this.isFarmVisitSelected = false;
          this.mappingAddObject.purpose = 0;
        }
      }
    }
    this.showAddButton();
  }

  setVillageName(){
    const villageId = this.mappingAddObject.villageName;
    if(villageId != 0){
      const selectedvillage = this.allVillages.find(item => item.id == villageId);
      if(selectedvillage){
        this.searchTextFarmers = selectedvillage.village_name;
      }
    }
    this.showAddButton();
  }

  handleVisitFileInput(file: FileList) {
    
    for(let i = 0; i < file.length; i++){
      const fileItem = file.item(i);
      const msg = this.commonService.ValidateFile(fileItem);

      if (msg && msg.length > 0) {
        this.toastrService.warning(msg);
        return;
      }
      new Compressor(fileItem, {
        quality: environment.imageCompress.quality,
        maxWidth: environment.imageCompress.maxWidth,
        maxHeight: environment.imageCompress.maxHeight,
        mimeType: environment.imageCompress.mimeType,
        convertSize: environment.imageCompress.convertSize,
        success(result) {
          setFarmerImageData(result);
        },
        error(err) {
          console.log(err.message);
        },
      });

      const setFarmerImageData = (result) => {
        this.commonService.blobToBase64(result).then((imageData) => {
          const obj = {
            "image_file": imageData,
            "image_for": fileItem.name
          }
          this.uploadedVisitFiles.push(obj);
          this.disableAddButton = false;
        });
      };      
    }
  }

  removeVisitFile(fileName, template, id?) {
    const file = {
      id: (id ? id : null),
      fileName: fileName
    }
    this.fileToDelete = file;
    this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  confirmFileDelete(decision) {
    console.log(decision);
    if (decision == "Yes") {
      if (this.uploadedVisitFiles.length > 0) {
        const x = this.uploadedVisitFiles.find(item => item.image_for == this.fileToDelete.fileName);
        const index = this.uploadedVisitFiles.indexOf(x);
        if (index !== -1) {
          this.uploadedVisitFiles.splice(index, 1);
          this.fileDeleteReset();
        }
      }
      if (this.mappingAddObject.uploadedVisitFilesOld.length > 0 && this.fileToDelete !== null) {
        const x = this.mappingAddObject.uploadedVisitFilesOld.find(item => item.id == this.fileToDelete.id);
        if(x){
          x.deleted = 1;
        }
        this.fileDeleteReset();
      }
      this.disableAddButton = false;
    } else {
      this.fileDeleteReset();
    }
  }

  fileDeleteReset() {
    this.fileDeletePopup.hide();
    this.fileToDelete = null;
  }

  downloadImage(imageBase64, fileName) {
    this.downloadPdf(imageBase64, fileName);
  }

  downloadPdf(base64String, fileName) {
    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName;
    link.click();
  } 
}
