import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { VisitService } from '../../services/visits.service';
import { FarmerService } from '../../services/farmer.service';
import { TrainingService } from '../../services/training.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import Compressor from 'compressorjs';
import * as XLSX from 'xlsx';
var VisitManagementComponent = /** @class */ (function () {
    function VisitManagementComponent(route, router, spinner, userService, modalService, projectService, visitService, farmerService, translate, trainingService, commonService, deviceService, toastrService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.spinner = spinner;
        this.userService = userService;
        this.modalService = modalService;
        this.projectService = projectService;
        this.visitService = visitService;
        this.farmerService = farmerService;
        this.translate = translate;
        this.trainingService = trainingService;
        this.commonService = commonService;
        this.deviceService = deviceService;
        this.toastrService = toastrService;
        this.searchText = '';
        this.searchTextCR = '';
        this.page = 1;
        this.itemsPerPage = 10;
        this.currentUser = this.userService.getCurrentUser();
        this.confirmTypeModal = true;
        this.isAscending = true;
        this.isAscendingName = true;
        this.isAscendingType = true;
        this.isAscendingTrainerName = true;
        this.isAscendingTypeCR = true;
        this.isAscendingTrainerNameCR = true;
        this.isAscendingDueDate = true;
        this.isAscendingDueDateCR = true;
        this.isAscendingTrainingDate = true;
        this.isAscendingCount = true;
        this.isAscendingCR = true;
        this.canExportToExcel = false;
        this.formIsEdited = false;
        this.mappingAddObject = {
            "visitType": 0,
            "description": '',
            "villageName": 0,
            "agent": 0,
            "duedate": null,
            "purpose": 0,
            "uploadedVisitFilesOld": []
        };
        this.disableAddButton = true;
        this.isEdit = false;
        this.isDueDateOpen = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.maxDate = new Date();
        this.addVisit = false;
        this.farmers = [];
        this.selectedFarmers = [];
        this.allFarmers = [];
        this.farmersListPage = 1;
        this.isFarmVisitSelected = false;
        this.farmVisits = [];
        this.CRs = [];
        this.uploadedVisitFiles = [];
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
    }
    VisitManagementComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, canExportToExcel, canExportToExcel;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.projectId = +this.route.snapshot.params['id'];
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.projectService.getProjectDashboardData(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0]);
                        if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
                            this.UPM = this.project.ProjectMapping.find(function (item) { return item.users_id === _this.currentUser.id; });
                        }
                        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
                        }
                        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
                        }
                        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
                            canExportToExcel = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP" && item.user_type_id === _this.currentUser.role.id); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                        }
                        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
                            canExportToExcel = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP"); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                        }
                        this.getAllVisits();
                        this.getAllPreRequisites();
                        this.isDesktopDevice = this.deviceService.isDesktop();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisitManagementComponent.prototype.getAllVisits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, _i, _a, item;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.visitService.getAllVisits(this.projectId).toPromise(),
                                this.visitService.getAllVisitTypes().toPromise(),
                                this.visitService.getAllVisitPurposes().toPromise(),
                            ])];
                    case 1:
                        request = _b.sent();
                        if (request[0]['msg'] === 'success') {
                            this.visits = request[0]['data'];
                        }
                        if (request[1]['msg'] === 'success') {
                            this.allVisitTypes = request[1]['data'];
                        }
                        if (request[2]['msg'] === 'success') {
                            this.allVisitPurposes = request[2]['data'];
                        }
                        for (_i = 0, _a = this.visits; _i < _a.length; _i++) {
                            item = _a[_i];
                            item.visit_type = item.MultiValueMaster.visit_type;
                            item.village_name = item.region_villages.village_name;
                        }
                        this.separateVisitTypes();
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisitManagementComponent.prototype.getAllPreRequisites = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, _i, _a, item;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.trainingService.get_all_trainer(this.projectId).toPromise(),
                                this.visitService.getRegionVillages(this.projectId).toPromise(),
                                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _b.sent();
                        if (request[0]['msg'] === 'success') {
                            this.allTrainers = request[0]['data'];
                        }
                        if (request[1]['msg'] === 'success') {
                            this.allVillages = request[1]['data'];
                        }
                        if (request[2]['msg'] === 'success') {
                            this.farmers = request[2]['data'].filter(function (item) { return !item.is_discontinued; });
                            for (_i = 0, _a = this.farmers; _i < _a.length; _i++) {
                                item = _a[_i];
                                item.village_name = item.region_village.village_name;
                            }
                        }
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisitManagementComponent.prototype.separateVisitTypes = function () {
        this.farmVisits = [];
        this.CRs = [];
        var _loop_1 = function (item) {
            var type = this_1.allVisitTypes.find(function (item2) { return item2.id == item.visit_type_id; });
            if (type) {
                if (type.value == 'Farm Visit') {
                    this_1.farmVisits.push(item);
                }
                else {
                    this_1.CRs.push(item);
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.visits; _i < _a.length; _i++) {
            var item = _a[_i];
            _loop_1(item);
        }
        for (var _b = 0, _c = this.farmVisits; _b < _c.length; _b++) {
            var item = _c[_b];
            item.farmer_count = item.farmer_ids.length;
        }
    };
    VisitManagementComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    VisitManagementComponent.prototype.addNewVisit = function (template) {
        if (this.currentUser.role.id > 3) {
            this.mappingAddObject.agent = this.currentUser.id;
        }
        this.resetMappingObject();
        this.searchTextFarmers = "";
        this.addVisit = true;
        this.uploadedVisitFiles = [];
    };
    VisitManagementComponent.prototype.showAddButton = function () {
        if (this.mappingAddObject.visitType != 0 && this.mappingAddObject.villageName != 0 && this.mappingAddObject.agent != 0 && this.mappingAddObject.duedate != null) {
            this.disableAddButton = false;
        }
        else {
            this.disableAddButton = true;
        }
    };
    VisitManagementComponent.prototype.saveVisit = function () {
        var _this = this;
        this.spinner.show();
        var farmersSelected = [];
        for (var _i = 0, _a = this.selectedFarmers; _i < _a.length; _i++) {
            var item = _a[_i];
            var obj = {
                "farmer_id": item,
                "is_visited": 1
            };
            farmersSelected.push(obj);
        }
        var date = new Date(this.mappingAddObject.duedate);
        var visitDate = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
        var documentData = this.uploadedVisitFiles;
        var requestParams = {
            "visit_type_id": this.mappingAddObject.visitType,
            "agent_id": this.mappingAddObject.agent,
            "description": this.mappingAddObject.description,
            "village_area_id": this.mappingAddObject.villageName,
            "due_date": visitDate,
            "project_id": this.projectId,
            "visit_purpose": this.mappingAddObject.purpose,
            "visit_recordings_farmer_dtl": farmersSelected,
            "visit_recordings_documents_dtl": documentData
        };
        this.visitService.saveUpdateVisit(requestParams, this.isEdit, this.projectId).subscribe(function (result) {
            if (result['msg'] === 'success') {
                _this.commonService.showToast('success', 'visit_created_successfully', {});
                _this.addVisit = false;
                _this.getAllVisits();
                _this.resetMappingObject();
                _this.uploadedVisitFiles = [];
                _this.spinner.hide();
            }
        });
    };
    VisitManagementComponent.prototype.updateVisit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmersSelected, curMapping, dtlArray, _loop_2, this_2, _i, _a, item, date, visitDate, documentData, requestParams;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner.show();
                        farmersSelected = [];
                        curMapping = this.visits.find(function (item) { return item.id === _this.currentVisitID; });
                        dtlArray = [];
                        if (curMapping) {
                            dtlArray = curMapping.visit_recordings_farmer_dtl;
                        }
                        _loop_2 = function (item) {
                            var exist = dtlArray.find(function (item2) { return item2.farmer_id == item; });
                            console.log(exist);
                            if (exist) {
                                var obj = {
                                    "id": exist.id,
                                    "farmer_id": item,
                                    "is_visited": this_2.selectedFarmers.indexOf(item) > -1 ? 1 : 0
                                };
                                farmersSelected.push(obj);
                            }
                            else if (this_2.selectedFarmers.indexOf(item) > -1) {
                                var obj = {
                                    "farmer_id": item,
                                    "is_visited": 1
                                };
                                farmersSelected.push(obj);
                            }
                        };
                        this_2 = this;
                        for (_i = 0, _a = this.allFarmers; _i < _a.length; _i++) {
                            item = _a[_i];
                            _loop_2(item);
                        }
                        date = new Date(this.mappingAddObject.duedate);
                        visitDate = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
                        documentData = this.mappingAddObject.uploadedVisitFilesOld.concat(this.uploadedVisitFiles);
                        requestParams = {
                            "id": this.currentVisitID,
                            "visit_type_id": this.mappingAddObject.visitType,
                            "agent_id": this.mappingAddObject.agent,
                            "description": this.mappingAddObject.description,
                            "village_area_id": this.mappingAddObject.villageName,
                            "due_date": visitDate,
                            "project_id": this.projectId,
                            "visit_purpose": this.mappingAddObject.purpose,
                            "visit_recordings_farmer_dtl": farmersSelected,
                            "visit_recordings_documents_dtl": documentData
                        };
                        return [4 /*yield*/, this.visitService.saveUpdateVisit(requestParams, this.isEdit, this.projectId).subscribe(function (result) {
                                if (result['msg'] === 'success') {
                                    _this.commonService.showToast('success', 'visit_updated_successfully', {});
                                    _this.addVisit = false;
                                    _this.getAllVisits();
                                    _this.resetMappingObject();
                                    _this.uploadedVisitFiles = [];
                                    _this.spinner.hide();
                                }
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VisitManagementComponent.prototype.editVisit = function (id, type) {
        this.isEdit = true;
        this.currentVisitID = id;
        var curMapping = this.visits.find(function (item) { return item.id === id; });
        if (curMapping) {
            console.log("curMapping:", curMapping);
            this.mappingAddObject.visitType = curMapping.visit_type_id;
            this.mappingAddObject.villageName = curMapping.region_villages.id;
            this.mappingAddObject.description = curMapping.description;
            this.mappingAddObject.agent = curMapping.agent_id;
            this.mappingAddObject.duedate = curMapping.due_date ? new Date(curMapping.due_date) : null;
            this.mappingAddObject.purpose = 0;
            this.mappingAddObject.uploadedVisitFilesOld = curMapping.visit_recordings_documents_dtl;
        }
        if (type == 'Farm Visit') {
            this.isFarmVisitSelected = true;
            this.mappingAddObject.purpose = parseInt(curMapping.visit_purpose);
            this.selectedFarmers = curMapping.farmer_ids;
            this.allFarmers = curMapping.farmer_ids;
            this.searchTextFarmers = curMapping.village_name;
        }
        else {
            this.isFarmVisitSelected = false;
        }
        this.addVisit = true;
        console.log("this.mappingAddObject:", this.mappingAddObject);
    };
    VisitManagementComponent.prototype.closePopUp = function (template, form) {
        if (!this.isEdit) {
            if (this.mappingAddObject.visitType != 0 || this.mappingAddObject.villageName != 0 || this.mappingAddObject.agent != 0) {
                this.addVisitModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
            }
            else {
                this.resetMappingObject();
                this.addVisit = false;
            }
        }
        else {
            if (form.dirty) {
                this.addVisitModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
            }
            else {
                this.resetMappingObject();
                this.addVisit = false;
            }
        }
    };
    VisitManagementComponent.prototype.closePopUpYes = function () {
        this.resetMappingObject();
        this.addVisitModal.hide();
        this.addVisit = false;
    };
    VisitManagementComponent.prototype.closePopUpNo = function () {
        this.addVisitModal.hide();
    };
    VisitManagementComponent.prototype.closePage = function (template, form) {
        if (form.dirty) {
            this.addVisitModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.resetMappingObject();
            this.addVisit = false;
        }
    };
    VisitManagementComponent.prototype.resetMappingObject = function () {
        this.mappingAddObject.visitType = 0;
        this.mappingAddObject.villageName = 0;
        this.mappingAddObject.description = null;
        this.mappingAddObject.agent = 0;
        this.mappingAddObject.duedate = null;
        this.mappingAddObject.purpose = 0;
        this.mappingAddObject.uploadedVisitFilesOld = [];
        this.disableAddButton = true;
        this.isFarmVisitSelected = false;
        this.selectedFarmers = [];
        this.isEdit = false;
    };
    VisitManagementComponent.prototype.exportAllData = function (template) {
        this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    VisitManagementComponent.prototype.submitType = function () {
        this.exportToExcel(this.selectedType);
        this.closeTypePopup();
    };
    VisitManagementComponent.prototype.closeTypePopup = function (fromUI) {
        if (fromUI) {
            if (this.selectedType) {
                this.confirmTypeModal = false;
            }
            else if (this.selectTypeModal) {
                this.selectTypeModal.hide();
                this.selectedType = undefined;
                this.confirmTypeModal = true;
            }
        }
        else if (this.selectTypeModal) {
            this.confirmTypeModal = true;
            this.selectTypeModal.hide();
            this.selectedType = undefined;
        }
    };
    VisitManagementComponent.prototype.confirmTypeModalFn = function (decision) {
        if (decision == "Yes") {
            this.closeTypePopup();
            this.confirmTypeModal = true;
        }
        else {
            this.confirmTypeModal = true;
        }
    };
    VisitManagementComponent.prototype.exportToExcel = function (type) {
        var _this = this;
        this.spinner.show();
        this.visitService.visitManagementExportToExcel(this.projectId, type).subscribe(function (result) {
            //console.log('result', result);
            var wb = XLSX.utils.book_new();
            var wsBooks = XLSX.utils.json_to_sheet(result.message);
            var wscols = [
                { wpx: 130 },
                { wpx: 130 },
                { wpx: 130 },
                { wpx: 200 },
                { wpx: 130 },
                { wpx: 130 },
                { wpx: 130 }
            ];
            wsBooks['!cols'] = wscols;
            var sheetName = "Sheet 1";
            XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
            var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            var excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            var blob = new Blob([excelBuffer], { type: fileType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + '-' + mm + '-' + yyyy;
            _this.translate.get('visit_management').subscribe(function (val) {
                linkdownload.download = "P2F-Roots_" + val + "_ExcelExport_" + today1 + ".xlsx";
                linkdownload.style.display = 'none';
                document.body.appendChild(linkdownload);
                linkdownload.click();
                linkdownload.remove();
            }, function (err) { return console.log('err', err); });
            // var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            // const blob = new Blob([result], {type: contentType});
            // const linkdownload = document.createElement('a');
            // linkdownload.href = URL.createObjectURL(blob);
            // var today = new Date();
            // var dd = String(today.getDate()).padStart(2, '0');
            // var mm = String(today.getMonth() + 1).padStart(2, '0');
            // var yyyy = today.getFullYear();
            // var today1 = `${dd}-${mm}-${yyyy}`;
            // this.translate.get('visit_management').subscribe((val) => {
            //   linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
            //   linkdownload.style.display = 'none';
            //   document.body.appendChild(linkdownload);
            //   linkdownload.click();
            // }, err => console.log('err', err));
            _this.spinner.hide();
        }, function (err) {
            console.log('err', err);
            _this.spinner.hide();
        });
    };
    VisitManagementComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['village_name', 'farmer_count', 'agent_name', 'due_date'];
        var finalObj = {};
        if (this.farmVisits === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    VisitManagementComponent.prototype.getGridFiltersCR = function () {
        var _this = this;
        var gridColums = ['village_name', 'agent_name', 'due_date'];
        var finalObj = {};
        if (this.CRs === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchTextCR;
            });
        }
        return finalObj;
    };
    VisitManagementComponent.prototype.onKeydownHandler = function (event) {
        this.closeTypePopup(true);
    };
    VisitManagementComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    VisitManagementComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        this.isAscendingType = sortBy !== 'village_name';
        this.isAscendingTrainerName = sortBy !== 'agent_name';
        this.isAscendingDueDate = sortBy !== 'due_date';
        this.isAscendingCount = sortBy !== 'farmer_count';
        // Sort of new fields added
        if (sortBy === 'village_name' || sortBy === 'agent_name') {
            this.farmVisits.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'due_date' || sortBy === 'farmer_count') {
            this.farmVisits.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    VisitManagementComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        this.isAscendingType = sortBy === 'village_name';
        this.isAscendingTrainerName = sortBy === 'agent_name';
        this.isAscendingDueDate = sortBy === 'due_date';
        this.isAscendingCount = sortBy === 'farmer_count';
        // Sort of new fields added
        if (sortBy === 'village_name' || sortBy === 'agent_name') {
            this.farmVisits.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'due_date' || sortBy === 'farmer_count') {
            this.farmVisits.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    VisitManagementComponent.prototype.sortByCR = function (sortOn) {
        this.isAscendingCR ? this.ascendicCR(sortOn) : this.descendicCR(sortOn);
    };
    VisitManagementComponent.prototype.ascendicCR = function (sortBy) {
        this.isAscendingCR = false;
        this.isAscendingTypeCR = sortBy !== 'village_name';
        this.isAscendingTrainerNameCR = sortBy !== 'agent_name';
        this.isAscendingDueDateCR = sortBy !== 'due_date';
        // Sort of new fields added
        if (sortBy === 'village_name' || sortBy === 'agent_name') {
            this.CRs.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'due_date') {
            this.CRs.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    VisitManagementComponent.prototype.descendicCR = function (sortBy) {
        this.isAscendingCR = true;
        this.isAscendingTypeCR = sortBy === 'village_name';
        this.isAscendingTrainerNameCR = sortBy === 'agent_name';
        this.isAscendingDueDateCR = sortBy === 'due_date';
        // Sort of new fields added
        if (sortBy === 'village_name' || sortBy === 'agent_name') {
            this.CRs.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'due_date') {
            this.CRs.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    VisitManagementComponent.prototype.trackByTrainer = function (index, dataItem) {
        return dataItem.groupName;
    };
    VisitManagementComponent.prototype.getGridFiltersFarmers = function () {
        var _this = this;
        var gridColums = ['farmer_code', 'name', 'tel_number', 'official_id', 'age', 'village_name', 'village_collectors', 'cr_date'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchTextFarmers;
            });
        }
        return finalObj;
    };
    // Select / deselect all farmers in the array
    VisitManagementComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length === this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var _i = 0, _a = this.farmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedFarmers.push(val.id);
                if (this.allFarmers.indexOf(val.id) < 0) {
                    this.allFarmers.push(val.id);
                }
            }
        }
        this.showAddButton();
    };
    // Toggle a particular farmer's selection status
    VisitManagementComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedFarmers.push(id);
            if (this.allFarmers.indexOf(id) < 0) {
                this.allFarmers.push(id);
            }
        }
        this.showAddButton();
    };
    VisitManagementComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length === this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    VisitManagementComponent.prototype.visitTypeSelected = function () {
        var visitId = this.mappingAddObject.visitType;
        if (visitId != 0) {
            var selectedVisitType = this.allVisitTypes.filter(function (item) { return item.id == visitId; });
            if (selectedVisitType.length > 0) {
                if (selectedVisitType[0].value == 'Farm Visit') {
                    this.isFarmVisitSelected = true;
                }
                else {
                    this.isFarmVisitSelected = false;
                    this.mappingAddObject.purpose = 0;
                }
            }
        }
        this.showAddButton();
    };
    VisitManagementComponent.prototype.setVillageName = function () {
        var villageId = this.mappingAddObject.villageName;
        if (villageId != 0) {
            var selectedvillage = this.allVillages.find(function (item) { return item.id == villageId; });
            if (selectedvillage) {
                this.searchTextFarmers = selectedvillage.village_name;
            }
        }
        this.showAddButton();
    };
    VisitManagementComponent.prototype.handleVisitFileInput = function (file) {
        var _this = this;
        var _loop_3 = function (i) {
            var fileItem = file.item(i);
            var msg = this_3.commonService.ValidateFile(fileItem);
            if (msg && msg.length > 0) {
                this_3.toastrService.warning(msg);
                return { value: void 0 };
            }
            new Compressor(fileItem, {
                quality: environment.imageCompress.quality,
                maxWidth: environment.imageCompress.maxWidth,
                maxHeight: environment.imageCompress.maxHeight,
                mimeType: environment.imageCompress.mimeType,
                convertSize: environment.imageCompress.convertSize,
                success: function (result) {
                    setFarmerImageData(result);
                },
                error: function (err) {
                    console.log(err.message);
                },
            });
            var setFarmerImageData = function (result) {
                _this.commonService.blobToBase64(result).then(function (imageData) {
                    var obj = {
                        "image_file": imageData,
                        "image_for": fileItem.name
                    };
                    _this.uploadedVisitFiles.push(obj);
                    _this.disableAddButton = false;
                });
            };
        };
        var this_3 = this;
        for (var i = 0; i < file.length; i++) {
            var state_1 = _loop_3(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    VisitManagementComponent.prototype.removeVisitFile = function (fileName, template, id) {
        var file = {
            id: (id ? id : null),
            fileName: fileName
        };
        this.fileToDelete = file;
        this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    VisitManagementComponent.prototype.confirmFileDelete = function (decision) {
        var _this = this;
        console.log(decision);
        if (decision == "Yes") {
            if (this.uploadedVisitFiles.length > 0) {
                var x = this.uploadedVisitFiles.find(function (item) { return item.image_for == _this.fileToDelete.fileName; });
                var index = this.uploadedVisitFiles.indexOf(x);
                if (index !== -1) {
                    this.uploadedVisitFiles.splice(index, 1);
                    this.fileDeleteReset();
                }
            }
            if (this.mappingAddObject.uploadedVisitFilesOld.length > 0 && this.fileToDelete !== null) {
                var x = this.mappingAddObject.uploadedVisitFilesOld.find(function (item) { return item.id == _this.fileToDelete.id; });
                if (x) {
                    x.deleted = 1;
                }
                this.fileDeleteReset();
            }
            this.disableAddButton = false;
        }
        else {
            this.fileDeleteReset();
        }
    };
    VisitManagementComponent.prototype.fileDeleteReset = function () {
        this.fileDeletePopup.hide();
        this.fileToDelete = null;
    };
    VisitManagementComponent.prototype.downloadImage = function (imageBase64, fileName) {
        this.downloadPdf(imageBase64, fileName);
    };
    VisitManagementComponent.prototype.downloadPdf = function (base64String, fileName) {
        var source = base64String;
        var link = document.createElement("a");
        link.href = source;
        link.download = fileName;
        link.click();
    };
    return VisitManagementComponent;
}());
export { VisitManagementComponent };
