import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, TemplateRef, AfterViewInit, Renderer } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { environment } from '../../../environments/environment';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TrainingService } from '../../services/training.service';
import { DataConsentService } from '../../services/data-consent.service';
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
var FarmermanagementComponent = /** @class */ (function () {
    function FarmermanagementComponent(route, commonService, router, farmerService, projectService, translate, toastrService, modalService, localeService, formBuilder, revisionService, renderer, spinner, userService, trainingService, dataConsentService, dynamicQuestionnaireService, sanitizer, locale) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.router = router;
        this.farmerService = farmerService;
        this.projectService = projectService;
        this.translate = translate;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.formBuilder = formBuilder;
        this.revisionService = revisionService;
        this.renderer = renderer;
        this.spinner = spinner;
        this.userService = userService;
        this.trainingService = trainingService;
        this.dataConsentService = dataConsentService;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.sanitizer = sanitizer;
        this.locale = locale;
        this.projectId = 0;
        this.farmers = [];
        this.productType = [];
        this.managers = [];
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.isAscendingAge = true;
        this.isAscendingSex = true;
        this.isAscendingVillage = true;
        this.isAscendingVC = true; // VC name sort added
        this.isAscendingFarmerNo = true; //Official farmer id added 
        this.sortLabel = '';
        this.showSpinner = false;
        this.isFieldAgent = false;
        this.isManager = false;
        this.isDeeproots = false;
        this.farmerFilterString = { name: '' };
        this.page = 1;
        this.itemsPerPage = 10;
        this.isInIDBInThisProject = [];
        this.showSyncSuccessAlert = false;
        this.olderSnapshots = [];
        this.minDate = new Date();
        this.noSnapshotsInProject = false;
        this.fetchingSnapshotInfo = true;
        this.disableCreateSnapshot = false;
        this.disableCloseSnapshot = false;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.oldSnapshotId = 0;
        this.arrSnapshotRevisions = [];
        this.snapshotRevision = [];
        this.confirmModal = true;
        this.confirmModalRegistration = true;
        this.registerFarmerGender = 3;
        this.addFarmer = false;
        this.editFarmer = false;
        this.selectedFarmers = [];
        this.ageSliderOptions = {
            floor: 18,
            ceil: 99
        };
        this.farmerAgeSliderValue = 18;
        this.farmerAgeSliderValueUpdate = 18;
        this.canRead = false;
        this.canWrite = false;
        this.canDelete = false;
        this.canExportToExcel = false;
        this.canPrintIDCard = false;
        this.canEmailIDCard = false;
        this.canExportTraining = false;
        this.missingTrainings = [];
        this.farmerMissingTrainings = [];
        this.confirmTypeModal = true;
        this.farmerCertifications = [];
        this.canExportConsent = false;
        this.filteredfarmers = [];
        if (this.locale && this.locale.length > 2) {
            this.locale = this.locale.substr(0, 2);
        }
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('file_upload_component_default_txt').subscribe(function (result) {
            _this.noFileChosenDefault = result;
        });
        this.translate.get('revision').subscribe(function (result) {
            _this.revisionTxt = result;
        });
        this.translate.get('created_successfully').subscribe(function (result) {
            _this.createSuccessTxt = result;
        });
        this.translate.get('there_no_active_snapshot_for_this_project').subscribe(function (result) {
            _this.thereNoActiveSnapshotForThisProject = result;
        });
        this.translate.get('farmer').subscribe(function (result) {
            _this.farmerLabel = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
        this.translate.get('data_will_be_synced').subscribe(function (result) {
            _this.tooltipText2 = result;
        });
        this.translate.get('certifications_completed').subscribe(function (result) {
            _this.certificationsCompleted = result;
        });
        this.translate.get('consent_template_not_available').subscribe(function (result) {
            _this.consentTemplateNotAvailable = result;
        });
        this.translate.get('consent_not_available_selected_farmers').subscribe(function (result) {
            _this.consentNotAvailable = result;
        });
        this.translate.get('no_consent_found_this_language').subscribe(function (result) {
            _this.noConsentFoundLanguage = result;
        });
    }
    FarmermanagementComponent.prototype.snapshotChanged = function () {
        var _this = this;
        this.snapshotRevision = this.arrSnapshotRevisions.filter(function (x) { return x.snapshot_id_ref === _this.oldSnapshotId; });
    };
    FarmermanagementComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var code, requests, _i, _a, item, _loop_1, this_1, _b, _c, _d, key, value, _loop_2, this_2, _e, _f, _g, key, value, requests, canExportToExcel, canPrintIDCard, canEmailIDCard, canExportTraining, canExportConsent, canExportToExcel, canPrintIDCard, canEmailIDCard, canExportTraining, canExportConsent;
            var _this = this;
            return tslib_1.__generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        this.scrollToTop();
                        this.projectId = +this.route.snapshot.params['id'];
                        code = this.commonService.checkRole();
                        this.currentUser = this.userService.getCurrentUser();
                        if (code == 'FAG') {
                            this.isFieldAgent = true;
                        }
                        else if (code === 'PMG') {
                            this.isManager = true;
                        }
                        else if (code === 'SYS1') {
                            this.isDeeproots = true;
                        }
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        this.project = this.route.snapshot.data.project.project;
                        if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
                            this.UPM = this.project.ProjectMapping.find(function (item) { return item.users_id === _this.currentUser.id; });
                        }
                        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
                        }
                        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
                        }
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                this.trainingService.getFarmerTrainingIndicators(this.projectId).toPromise(),
                                this.trainingService.getFarmerExportTypes().toPromise(),
                                this.dataConsentService.getConsentTemplate(this.projectId).toPromise(),
                                this.trainingService.getFarmerCertificationList(this.projectId).toPromise()
                            ])];
                    case 1:
                        requests = _h.sent();
                        this.farmers = requests[0].data;
                        if (requests[1].msg == 'success') {
                            this.missingTrainings = requests[1].data;
                        }
                        for (_i = 0, _a = this.farmers; _i < _a.length; _i++) {
                            item = _a[_i];
                            item.village_name = item.region_village.village_name;
                            item.trainingMissed = false;
                        }
                        _loop_1 = function (key, value) {
                            var farmer = this_1.farmers.find(function (item) { return item.id == key; });
                            if (farmer) {
                                farmer.trainingMissed = true;
                            }
                        };
                        this_1 = this;
                        for (_b = 0, _c = Object.entries(this.missingTrainings); _b < _c.length; _b++) {
                            _d = _c[_b], key = _d[0], value = _d[1];
                            _loop_1(key, value);
                        }
                        if (requests[2].msg == 'success') {
                            this.exportTypes = requests[2].data;
                        }
                        if (requests[3].msg == 'success' && requests[3].data) {
                            this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[3].data.template_content);
                        }
                        if (requests[4].msg == 'success') {
                            this.farmerCertifications = requests[4].data;
                        }
                        _loop_2 = function (key, value) {
                            var farmer = this_2.farmers.find(function (item) { return item.id == key; });
                            if (farmer) {
                                var certificationString_1 = this_2.certificationsCompleted + " : ";
                                value.forEach(function (item, index) {
                                    if (index == value.length - 1) {
                                        certificationString_1 = "" + certificationString_1 + item.shortname;
                                    }
                                    else {
                                        certificationString_1 = "" + certificationString_1 + item.shortname + ", ";
                                    }
                                });
                                farmer.certificationsCompleted = certificationString_1;
                            }
                        };
                        this_2 = this;
                        for (_e = 0, _f = Object.entries(this.farmerCertifications); _e < _f.length; _e++) {
                            _g = _f[_e], key = _g[0], value = _g[1];
                            _loop_2(key, value);
                        }
                        this.setOfflineData();
                        this.getFarmerConsents();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Promise.all([
                            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                            this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                            this.projectService.getProjectDashboardData(this.projectId).toPromise()
                        ])];
                    case 3:
                        requests = _h.sent();
                        this.project = this.route.snapshot.data.project.project;
                        if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
                            this.UPM = this.project.ProjectMapping.find(function (item) { return item.users_id === _this.currentUser.id; });
                        }
                        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
                        }
                        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
                        }
                        this.farmers = requests[1].data;
                        this.setFarmerRegData();
                        // Get any farmers saved in indexed db for this project
                        this.setOfflineData();
                        this.getFarmerConsents();
                        _h.label = 4;
                    case 4:
                        // For non-admin roles, define the services displayed array based on the services mapped to this project
                        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
                            canExportToExcel = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 3 && item.user_type_id === _this.currentUser.role.id); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canPrintIDCard = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 1 && item.user_type_id === _this.currentUser.role.id); });
                            if (canPrintIDCard && canPrintIDCard.length > 0) {
                                this.canPrintIDCard = true;
                            }
                            canEmailIDCard = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 2 && item.user_type_id === _this.currentUser.role.id); });
                            if (canEmailIDCard && canEmailIDCard.length > 0) {
                                this.canEmailIDCard = true;
                            }
                            canExportTraining = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_EXP" && item.user_type_id === _this.currentUser.role.id); });
                            if (canExportTraining && canExportTraining.length > 0) {
                                this.canExportTraining = true;
                            }
                            canExportConsent = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_CON_EXP" && item.user_type_id === _this.currentUser.role.id); });
                            if (canExportConsent && canExportConsent.length > 0) {
                                this.canExportConsent = true;
                            }
                        }
                        // For admin roles, define the services displayed array based on the services mapped to the ecosystem - whether or not mapped to the project
                        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
                            canExportToExcel = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 3); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canPrintIDCard = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 1); });
                            if (canPrintIDCard && canPrintIDCard.length > 0) {
                                this.canPrintIDCard = true;
                            }
                            canEmailIDCard = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 2); });
                            if (canEmailIDCard && canEmailIDCard.length > 0) {
                                this.canEmailIDCard = true;
                            }
                            canExportTraining = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_EXP"); });
                            if (canExportTraining && canExportTraining.length > 0) {
                                this.canExportTraining = true;
                            }
                            canExportConsent = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_CON_EXP"); });
                            if (canExportConsent && canExportConsent.length > 0) {
                                this.canExportConsent = true;
                            }
                        }
                        this.updateFarmerForm = this.formBuilder.group({
                            name: new FormControl('', [Validators.required]),
                            age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
                            sex: new FormControl(3, [Validators.required]),
                            area: new FormControl('', [Validators.required]),
                            email: new FormControl('', [Validators.email]),
                            telNumber: new FormControl('', [Validators.pattern("^[0-9]{5,13}$"),
                                Validators.minLength(5), Validators.maxLength(13)]),
                            photo_path: new FormControl('')
                        });
                        this.registerFarmerForm = this.formBuilder.group({
                            name: new FormControl('', [Validators.required]),
                            age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
                            sex: new FormControl(3, [Validators.required]),
                            area: new FormControl('', [Validators.required]),
                            email: new FormControl('', [Validators.email]),
                            telNumber: new FormControl('', [Validators.pattern("^[0-9]{5,13}$"),
                                Validators.minLength(5), Validators.maxLength(13)]),
                            photo_path: new FormControl('')
                        });
                        this.setVCMapping();
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmermanagementComponent.prototype.onKeydownHandler = function (event) {
        if (this.addFarmer) {
            this.declineRegisterFarmerPrompt(this.registerFarmerForm);
        }
        else if (this.editFarmer) {
            this.declineUpdateFarmerPrompt(this.updateFarmerForm);
        }
        this.closeMissingTrainingPopup();
        this.closeTypePopup(true);
    };
    FarmermanagementComponent.prototype.setOfflineData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmertmpobj, idbAppFarmers, _loop_3, this_3, i, idbAppFarmersUpdatedOffline, _loop_4, this_4, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                    case 1:
                        farmertmpobj = _a.sent();
                        idbAppFarmers = farmertmpobj.filter(function (x) { return x.project_id == _this.projectId && x.savetab == 'Tab0'; });
                        if (idbAppFarmers && idbAppFarmers.length > 0) {
                            idbAppFarmers.sort(function (a, b) {
                                var aDate = new Date(a.cr_date);
                                var bDate = new Date(b.cr_date);
                                return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
                            });
                            _loop_3 = function (i) {
                                idbAppFarmers[i]['region_village'] = this_3.project.ecosystem_region_master.region_villages.find(function (item) { return item.id === idbAppFarmers[i]['village_area_id']; });
                            };
                            this_3 = this;
                            for (i = 0; i < idbAppFarmers.length; i++) {
                                _loop_3(i);
                            }
                            this.farmers = idbAppFarmers.concat(this.farmers);
                        }
                        idbAppFarmersUpdatedOffline = farmertmpobj.filter(function (x) { return x.project_id == _this.projectId && x.savetab == 'Tab00'; });
                        if (idbAppFarmersUpdatedOffline && idbAppFarmersUpdatedOffline.length > 0) {
                            _loop_4 = function (i) {
                                var farmerUpdated = void 0;
                                if (idbAppFarmersUpdatedOffline[i].id > 0) {
                                    farmerUpdated = this_4.farmers.filter(function (item) { return item.id === idbAppFarmersUpdatedOffline[i].id; });
                                }
                                else {
                                    farmerUpdated = this_4.farmers.filter(function (item) { return item.tmp_id === idbAppFarmersUpdatedOffline[i].tmp_id.slice(0, -1); });
                                }
                                if (farmerUpdated && farmerUpdated.length > 0) {
                                    var index_1 = this_4.farmers.indexOf(farmerUpdated[0]);
                                    if (index_1 != null && index_1 > -1) {
                                        var villageCollectors = this_4.farmers[index_1].village_collectors;
                                        this_4.farmers[index_1] = idbAppFarmersUpdatedOffline[i];
                                        this_4.farmers[index_1]['region_village'] = this_4.project.ecosystem_region_master.region_villages.find(function (item) { return item.id == _this.farmers[index_1]['village_area_id']; });
                                        this_4.farmers[index_1]['village_collectors'] = villageCollectors;
                                    }
                                }
                            };
                            this_4 = this;
                            for (i = 0; i < idbAppFarmersUpdatedOffline.length; i++) {
                                _loop_4(i);
                            }
                        }
                        this.filteredfarmers = this.farmers;
                        this.sortFarmers();
                        this.setIndicatorValues();
                        this.setOfflineSignData();
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmermanagementComponent.prototype.setOfflineSignData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmersignobj, faremerWithTemId, _loop_5, _i, faremerWithTemId_1, item;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, idbAppPhoto.getAllProjects('farmerSignaturetmp')];
                    case 1:
                        farmersignobj = _a.sent();
                        faremerWithTemId = this.farmers.filter(function (x) { return x.tmp_id; });
                        _loop_5 = function (item) {
                            var findSignature = farmersignobj.find(function (x) { return x.tmp_id == item.tmp_id; });
                            if (findSignature) {
                                item.consentPending = false;
                                item.valid_upto = 730;
                            }
                        };
                        for (_i = 0, faremerWithTemId_1 = faremerWithTemId; _i < faremerWithTemId_1.length; _i++) {
                            item = faremerWithTemId_1[_i];
                            _loop_5(item);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmermanagementComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    FarmermanagementComponent.prototype.getProjectData = function () {
        var _this = this;
        this.projectService.GetProject(this.id).subscribe(function (response) {
            _this.projectData = response.data;
        });
    };
    FarmermanagementComponent.prototype.syncDone = function (args) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 3];
                        window.dispatchEvent(new Event('caching-now'));
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                // this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
                                this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        this.farmers = request[0].data;
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
                            ])
                                .then(function (result) {
                                if (result[0]['code'] === "success") {
                                    for (var i = 0; i < result[0]['message'].length; i++) {
                                        _this.loadFarmerDataIntoCache(_this.projectId, result[0]['message'][i], result[0]['message'][i + 1]);
                                    }
                                }
                            }).catch(function (e) { })];
                    case 2:
                        _a.sent();
                        this.setOfflineData();
                        this.getFarmerConsents();
                        window.dispatchEvent(new Event('data-synced'));
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FarmermanagementComponent.prototype.goToDashboard = function () {
        this.router.navigate(['dashboard']);
    };
    FarmermanagementComponent.prototype.goToViewFarmer = function (farmer) {
        var url;
        if (farmer.id && farmer.id > 0) {
            url = "/farmerprofileinfo/" + farmer.project_id + "/" + farmer.id;
        }
        else if (farmer.tmp_id && !navigator.onLine) {
            url = "/farmerprofileinfo/" + farmer.project_id + "/" + farmer.tmp_id;
        }
        else if (farmer.tmp_id && navigator.onLine) {
            this.commonService.showToast('warning', 'please_wait_data_sync', { preventDuplicates: true });
            return;
        }
        else {
            url = '/error';
        }
        this.router.navigate([url]);
    };
    FarmermanagementComponent.prototype.scrollToTop = function () {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    };
    FarmermanagementComponent.prototype.ngAfterViewInit = function () {
        if (this.project && this.project.QuesProjectSnapshotMappings && this.project.QuesProjectSnapshotMappings.length > 0) {
            // If the active snapshot is in draft stage, fetch complete QuesProjectSnapshotMappings object from server
            // If the active snapshot is not in draft stage, fetch the complete QuesProjectSnapshotMappings object from the cache...
            // ... have to wait for the managerpage ngAfterViewInit function to finish getting the response for this to work
        }
    };
    FarmermanagementComponent.prototype.getGridFilters = function () {
        var _this = this;
        //new fields added for search village_name and vc_name
        var gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name', 'cr_date'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    FarmermanagementComponent.prototype.ngOnDestroy = function () {
        this.showSpinner = false;
        clearInterval(this.getSnapshotInterval);
    };
    FarmermanagementComponent.prototype.goToEditProject = function () {
        if (navigator.onLine) {
            var url = "setup/" + this.project.id;
            this.router.navigate([url]);
        }
        else {
            this.toastrService.warning('You cannot edit a project while offline');
        }
    };
    FarmermanagementComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    FarmermanagementComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        this.isAscendingId = sortBy !== 'id';
        this.isAscendingName = sortBy !== 'name';
        this.isAscendingTel = sortBy !== 'tel_number';
        this.isAscendingDate = sortBy !== 'cr_date';
        this.isAscendingAge = sortBy !== 'age';
        this.isAscendingFarmerNo = sortBy !== 'official_id';
        this.isAscendingSex = sortBy !== 'sex';
        // Sort of new fields added
        this.isAscendingVillage = sortBy !== 'village_name';
        this.isAscendingVC = sortBy === 'village_collectors';
        // Sort of new fields added
        if (sortBy === 'name' || sortBy === 'village_name' || sortBy === 'village_collectors' || sortBy === 'official_id') {
            this.farmers.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'tel_number' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    FarmermanagementComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        this.isAscendingId = sortBy === 'id';
        this.isAscendingName = sortBy === 'name';
        this.isAscendingTel = sortBy === 'tel_number';
        this.isAscendingDate = sortBy === 'cr_date';
        this.isAscendingAge = sortBy === 'age';
        this.isAscendingFarmerNo = sortBy === 'official_id';
        this.isAscendingSex = sortBy === 'sex';
        // Sort of new fields added
        this.isAscendingVillage = sortBy === 'village_name';
        this.isAscendingVC = sortBy === 'village_collectors';
        // Sort of new fields added
        if (sortBy === 'name' || sortBy === 'village_name' || sortBy === 'village_collectors' || sortBy === 'official_id') {
            this.farmers.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'tel_number' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    FarmermanagementComponent.prototype.updateSyncStatusShown = function (args) {
        var _this = this;
        for (var i = 0; i < this.farmers.length; i++) {
            // if there is a non-zero farmer_id in the allData element, it is an update object
            this.farmers[i]['isInIDB'] = false;
        }
        var numberOfUnsyncedFarmers = this.isInIDBInThisProject.length;
        this.isInIDBInThisProject = [];
        if (numberOfUnsyncedFarmers && numberOfUnsyncedFarmers > 0) {
            numberOfUnsyncedFarmers = 0;
            this.showSyncSuccessAlert = true;
            setTimeout(function () {
                _this.showSyncSuccessAlert = false;
            }, 5000);
        }
    };
    FarmermanagementComponent.prototype.freezeQuestionnaire = function () {
        var url = "edit-questionnaire/" + this.project.id + "/" + this.snapshot.id;
        this.router.navigate([url]);
    };
    FarmermanagementComponent.prototype.bsRangeValueChange = function (args) {
        if (args) {
            if (args[0]) {
                if (args[0].getTime() !== 0) {
                    this.snapshotModifying['start_date'] = args[0];
                }
            }
            if (args[1]) {
                if (args[1].getTime() !== 0) {
                    this.snapshotModifying['end_date'] = args[1];
                }
            }
        }
    };
    FarmermanagementComponent.prototype.applyLocaleToDatePicker = function (pop) {
        pop.hide();
        pop.show();
    };
    FarmermanagementComponent.prototype.openRegisterFarmerModal = function (template) {
        this.addFarmer = true;
        this.registerFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    FarmermanagementComponent.prototype.registerFarmer = function () {
        var _this = this;
        this.registeringFarmer = true;
        var name = this.registerFarmerForm['value'].name;
        var age = this.registerFarmerForm['value'].age;
        var sex = this.registerFarmerForm['value'].sex;
        var area = this.registerFarmerForm['value'].area;
        var telNumber = this.registerFarmerForm['value'].telNumber;
        var email = this.registerFarmerForm['value'].email;
        if (!name || name.length === 0 || !age || age.length === 0 || !sex || sex.length === 0 || !area || area.length === 0) {
            return;
        }
        var farmerToRegister = {
            name: name,
            age: age,
            sex: sex,
            village_area: area,
            tel_number: telNumber,
            email: email,
            photo_path: null,
            project_id: this.projectId
        };
        if (navigator.onLine) {
            if (this.fileToUpload) {
                var formData = new FormData();
                formData.append('type', 'farmerAvatar');
                formData.append('projectId', this.projectId);
                formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
                this.farmerService.uploadFarmerImage(formData)
                    .subscribe(function (obj) {
                    if (obj.msg === 'success') {
                        farmerToRegister['photo_path'] = obj.data;
                        _this.farmerService.registerFarmer(farmerToRegister).subscribe(function (result) {
                            _this.registeringFarmer = false;
                            if (result.msg === 'success') {
                                _this.farmers.unshift(result.data);
                                result.data.cr_date = new Date();
                                if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                    _this.registerFarmerModal.hide();
                                }
                                _this.registerFarmerForm.setValue({
                                    name: '',
                                    age: '',
                                    sex: 3,
                                    area: '',
                                    telNumber: '',
                                    email: '',
                                    photo_path: ''
                                });
                                _this.genderChanged(3);
                                _this.registerFarmerForm.markAsUntouched();
                                _this.registerFarmerForm.markAsPristine();
                                _this.farmerAgeSliderValue = 1;
                                _this.fileToUpload = undefined;
                                _this.commonService.showToast('success', 'farmer_registration_successful', {});
                                _this.renderer.selectRootElement('#farmerNameAdd').focus();
                                _this.confirmModalRegistration = true;
                            }
                            else {
                                _this.confirmModalRegistration = true;
                                if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                    _this.registerFarmerModal.hide();
                                }
                            }
                        }, function (error) {
                            _this.confirmModalRegistration = true;
                            if (_this.registerFarmerModal) {
                                _this.registerFarmerModal.hide();
                            }
                        });
                    }
                });
            }
            else {
                this.farmerService.registerFarmer(farmerToRegister).subscribe(function (result) {
                    _this.registeringFarmer = false;
                    if (result.msg === 'success') {
                        _this.farmers.unshift(result.data);
                        result.data.cr_date = new Date();
                        if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                            _this.registerFarmerModal.hide();
                        }
                        _this.registerFarmerForm.setValue({
                            name: '',
                            age: '',
                            sex: 3,
                            area: '',
                            telNumber: '',
                            email: '',
                            photo_path: ''
                        });
                        _this.genderChanged(3);
                        _this.registerFarmerForm.markAsUntouched();
                        _this.registerFarmerForm.markAsPristine();
                        _this.farmerAgeSliderValue = 1;
                        _this.fileToUpload = undefined;
                        _this.confirmModalRegistration = true;
                        _this.renderer.selectRootElement('#farmerNameAdd').focus();
                        _this.commonService.showToast('success', 'farmer_registration_successful', {});
                    }
                    else {
                        _this.confirmModalRegistration = true;
                        if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                            _this.registerFarmerModal.hide();
                        }
                    }
                }, function (error) {
                    _this.confirmModalRegistration = true;
                    if (_this.registerFarmerModal) {
                        _this.registerFarmerModal.hide();
                    }
                });
            }
        }
        else {
            var finalObj_1 = {};
            var farmerObj_1 = farmerToRegister;
            Object.keys(farmerObj_1).forEach(function (key) {
                if (typeof farmerObj_1[key] === 'object' && farmerObj_1[key] != null) {
                    if (farmerObj_1[key].length && farmerObj_1[key].length > 0) {
                        //finalObj[key] = {};
                        finalObj_1[key] = farmerObj_1[key];
                    }
                    // else {
                    //   finalObj[key] = farmerObj[key];
                    // }
                }
                else {
                    finalObj_1[key] = farmerObj_1[key];
                }
            });
            var farmObj = new Array();
            finalObj_1['tmp_id'] = "FA" + this.commonService.getRandomNum().toString().slice(0, 10) + "_Tab0";
            finalObj_1['savetab'] = 'Tab0';
            finalObj_1['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
            finalObj_1['cr_date'] = new Date().getTime();
            finalObj_1['upd_date'] = new Date().getTime();
            finalObj_1['id'] = 0;
            farmObj[farmObj.length] = finalObj_1;
            idbApp.addStoreInfo(farmObj, 'farmerstmp');
            this.commonService.showToast('info', 'offline_sync_later', {});
            this.genderChanged(3);
            this.registerFarmerForm.markAsPristine();
            this.farmerAgeSliderValue = 1;
            this.registerSyncEvent();
            farmerToRegister['cr_date'] = new Date();
            farmerToRegister['tmp_id'] = finalObj_1['tmp_id'];
            if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                this.registerFarmerModal.hide();
            }
            this.registerFarmerForm.setValue({
                name: '',
                age: '',
                sex: 3,
                area: '',
                telNumber: '',
                email: '',
                photo_path: ''
            });
            this.genderChanged(3);
            this.registerFarmerForm.markAsUntouched();
            this.registerFarmerForm.markAsPristine();
            this.farmerAgeSliderValue = 1;
            this.registeringFarmer = false;
            this.confirmModal = true;
        }
    };
    FarmermanagementComponent.prototype.registerSyncEvent = function () {
        navigator.serviceWorker.register('/service-worker.js', {
            scope: './'
        })
            .then(function (reg) {
            reg.sync.register('oneTimeSync');
        })
            .catch(function (error) {
        });
    };
    FarmermanagementComponent.prototype.declineRegisterFarmerPrompt = function (form) {
        if (form.dirty) {
            this.confirmModalRegistration = false;
        }
        else {
            this.declineRegisterFarmer();
        }
    };
    FarmermanagementComponent.prototype.declineRegisterFarmer = function () {
        var _this = this;
        if (this.registerFarmerModal) {
            this.registerFarmerModal.hide();
        }
        this.registerFarmerForm.setValue({
            name: '',
            age: '',
            sex: 3,
            area: '',
            telNumber: '',
            email: '',
            photo_path: ''
        });
        this.genderChanged(3);
        this.registerFarmerForm.markAsUntouched();
        this.registerFarmerForm.markAsPristine();
        this.farmerAgeSliderValue = 1;
        this.addFarmer = false;
        setTimeout(function () { return _this.confirmModalRegistration = true; }, 1000);
    };
    FarmermanagementComponent.prototype.genderChanged = function (gender) {
        this.registerFarmerForm.patchValue({
            sex: gender
        });
        this.registerFarmerGender = gender;
    };
    FarmermanagementComponent.prototype.genderChangedUpdateFarmer = function (gender) {
        this.updateFarmerForm.patchValue({
            sex: gender
        });
    };
    FarmermanagementComponent.prototype.declineUpdateFarmer = function () {
        var _this = this;
        if (this.updateFarmerModal) {
            this.updateFarmerModal.hide();
        }
        this.updateFarmerForm.setValue({
            name: '',
            age: '',
            sex: '',
            area: '',
            telNumber: '',
            email: '',
            photo_path: ''
        });
        this.updateFarmerForm.markAsUntouched();
        this.updateFarmerForm.markAsPristine();
        this.editFarmer = false;
        setTimeout(function () { return _this.confirmModal = true; }, 1000);
    };
    FarmermanagementComponent.prototype.updateCache = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    /* file upload */
    FarmermanagementComponent.prototype.handleFileInput = function (file) {
        var fileItem = file.item(0);
        this.uploadingFileName = fileItem.name;
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg.length > 0) {
            // alert(msg);
            // this.toastrService.warning(msg);
            this.commonService.showToast('warning', msg, {});
            return;
        }
        this.fileToUpload = fileItem;
        var reader = new FileReader();
        reader.onload = function (event) {
        };
        reader.readAsDataURL(this.fileToUpload);
    };
    // makeRevision() {
    //   this.spinner.show();
    //   this.revisionService.MakeRevision(this.projectId).subscribe(
    //     (result) => {
    //       this.spinner.hide();
    //       this.toastrService.success(`${this.revisionTxt} ${result.data} ${this.createSuccessTxt}.`);
    //     },
    //     (error) => { 
    //       this.spinner.hide(); 
    //       this.toastrService.error(error.message); 
    //     }
    //   );
    // }
    // Delete farmer btn click function
    FarmermanagementComponent.prototype.deleteFarmer = function (template, farmer) {
        this.deletingFarmer = farmer;
        this.deleteFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    // Redirect the program based on user intput on delete modal
    FarmermanagementComponent.prototype.confirmModalDeleteFarmer = function (what) {
        if (what === 'yes') {
            if (navigator.onLine) {
                this.confirmDeleteFarmer(this.deletingFarmer);
            }
            else {
                // Push offline deleted farmer data in browser's IndexedDB
                var farmerObj_2 = this.deletingFarmer;
                var finalObj_2 = {};
                Object.keys(farmerObj_2).forEach(function (key) {
                    if (typeof farmerObj_2[key] === 'object' && farmerObj_2[key] != null) {
                        if (farmerObj_2[key].length && farmerObj_2[key].length > 0) {
                            finalObj_2[key] = farmerObj_2[key];
                        }
                    }
                    else {
                        finalObj_2[key] = farmerObj_2[key];
                    }
                });
                var farmObj_1 = new Array();
                if (farmerObj_2.id < 1) {
                    var rawFarmerId = farmerObj_2.tmp_id;
                    var index = rawFarmerId.indexOf('_');
                    var rawFarmerIdEdited = rawFarmerId.substr(0, index);
                    finalObj_2['tmp_id'] = rawFarmerIdEdited + "_Tab00";
                }
                else {
                    finalObj_2['tmp_id'] = farmerObj_2.farmer_code + "_Tab00";
                }
                finalObj_2['savetab'] = 'Tab00';
                finalObj_2['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                finalObj_2['deleted'] = 1;
                finalObj_2['upd_user_id'] = this.currentUser.id;
                finalObj_2['cr_user_id'] = this.currentUser.id;
                farmObj_1[farmObj_1.length] = finalObj_2;
                finalObj_2['cr_date'] = new Date().getTime();
                finalObj_2['upd_date'] = new Date().getTime();
                idbApp.addStoreInfo(farmObj_1, 'farmerstmp');
                this.farmers = this.farmers.filter(function (item) { return item.id !== farmObj_1[0].id; });
                this.deleteFarmerModal.hide();
                this.commonService.showToast('info', 'offline_sync_later', {});
            }
        }
        else {
            this.deletingFarmer = undefined;
            if (this.deleteFarmerModal) {
                this.deleteFarmerModal.hide();
            }
        }
    };
    //  Call farmer delete API when user is online
    FarmermanagementComponent.prototype.confirmDeleteFarmer = function (farmer) {
        var _this = this;
        if (this.deleteFarmerModal) {
            this.deleteFarmerModal.hide();
        }
        this.spinner.show();
        this.farmerService.deleteFarmer(farmer.id)
            .subscribe(function (result) {
            _this.deletingFarmer = undefined;
            if (result.msg === 'success') {
                _this.spinner.hide();
                _this.commonService.showToast('success', 'success_farmer_deleted', {});
                _this.farmers = _this.farmers.filter(function (item) { return item.id !== farmer.id; });
            }
            else {
                _this.commonService.showToast('error', 'error_farmer_deleted', {});
            }
        });
    };
    FarmermanagementComponent.prototype.updateFarmerDataModal = function (farmer, template) {
        this.farmerUpdating = farmer;
        this.updateFarmerForm.setValue({
            name: farmer.name,
            age: farmer.age,
            sex: farmer.sex,
            area: farmer.village_area,
            telNumber: farmer.tel_number,
            email: farmer.email ? farmer.email : '',
            photo_path: ''
        });
        this.farmerAgeSliderValueUpdate = farmer.age;
        this.editFarmer = true;
        this.updateFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    FarmermanagementComponent.prototype.goToFarmerDetials = function (farmer) {
        var url;
        if (farmer.id && farmer.id > 0) {
            url = "/farmerdetails/" + farmer.project_id + "/" + farmer.id;
        }
        else if (farmer.tmp_id && !navigator.onLine) {
            url = "/farmerdetails/" + farmer.project_id + "/" + farmer.tmp_id;
        }
        else if (farmer.tmp_id && navigator.onLine) {
            this.commonService.showToast('warning', 'please_wait_data_sync', { preventDuplicates: true });
            return;
        }
        else {
            url = '/error';
        }
        if (farmer.trainingMissed && navigator.onLine) {
            this.router.navigate([url], { queryParams: { trainingMissed: true } });
        }
        else {
            this.router.navigate([url]);
        }
    };
    FarmermanagementComponent.prototype.updateFarmer = function () {
        var _this = this;
        var name = this.updateFarmerForm['value'].name;
        var age = this.updateFarmerForm['value'].age;
        var sex = this.updateFarmerForm['value'].sex;
        var area = this.updateFarmerForm['value'].area;
        var telNumber = this.updateFarmerForm['value'].telNumber;
        var email = this.updateFarmerForm['value'].email;
        if (!name || name.length === 0 || !age || age.length === 0 || !sex || sex.length === 0 || !area || area.length === 0) {
            return;
        }
        this.farmerUpdating.name = name;
        this.farmerUpdating.age = age;
        this.farmerUpdating.sex = sex;
        this.farmerUpdating.village_area = area;
        this.farmerUpdating.tel_number = telNumber;
        this.farmerUpdating.email = email;
        if (navigator.onLine) {
            if (this.fileToUpload) {
                var formData = new FormData();
                formData.append('type', 'farmerAvatar');
                formData.append('projectId', this.projectId);
                formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
                this.farmerService.uploadFarmerImage(formData)
                    .subscribe(function (obj) {
                    if (obj.msg === 'success') {
                        _this.farmerUpdating['photo_path'] = obj.data;
                        _this.farmerService.updateFarmerRegData(_this.farmerUpdating)
                            .subscribe(function (result) {
                            if (result.msg === 'success') {
                                _this.updateFarmerForm.markAsUntouched();
                                _this.updateFarmerForm.markAsPristine();
                                if (_this.updateFarmerModal) {
                                    _this.updateFarmerModal.hide();
                                }
                                _this.commonService.showToast('success', 'success_farmer_updated', {});
                                _this.renderer.selectRootElement('#farmerNameEdit').focus();
                                _this.confirmModal = true;
                            }
                            else {
                                _this.commonService.showToast('error', 'error_farmer_updated', {});
                                _this.confirmModal = true;
                            }
                        });
                    }
                });
            }
            else {
                this.farmerService.updateFarmerRegData(this.farmerUpdating)
                    .subscribe(function (result) {
                    if (result.msg === 'success') {
                        _this.updateFarmerForm.markAsUntouched();
                        _this.updateFarmerForm.markAsPristine();
                        if (_this.updateFarmerModal) {
                            _this.updateFarmerModal.hide();
                        }
                        _this.commonService.showToast('success', 'success_farmer_updated', {});
                        _this.renderer.selectRootElement('#farmerNameEdit').focus();
                        _this.confirmModal = true;
                    }
                    else {
                        _this.commonService.showToast('error', 'error_farmer_updated', {});
                        _this.confirmModal = true;
                    }
                });
            }
        }
        else {
            var finalObj_3 = {};
            var farmerObj_3 = this.farmerUpdating;
            Object.keys(farmerObj_3).forEach(function (key) {
                if (typeof farmerObj_3[key] === 'object' && farmerObj_3[key] != null) {
                    if (farmerObj_3[key].length && farmerObj_3[key].length > 0) {
                        //finalObj[key] = {};
                        finalObj_3[key] = farmerObj_3[key];
                    }
                    // else {
                    //   finalObj[key] = farmerObj[key];
                    // }
                }
                else {
                    finalObj_3[key] = farmerObj_3[key];
                }
            });
            var farmObj = new Array();
            if (farmerObj_3.id < 1) {
                var rawFarmerId = farmerObj_3.tmp_id;
                var index = rawFarmerId.indexOf('_');
                var rawFarmerIdEdited = rawFarmerId.substr(0, index);
                finalObj_3['tmp_id'] = rawFarmerIdEdited + "_Tab00";
            }
            else {
                finalObj_3['tmp_id'] = farmerObj_3.farmer_code + "_Tab00";
            }
            finalObj_3['savetab'] = 'Tab00';
            finalObj_3['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
            finalObj_3['project_id'] = this.projectId;
            finalObj_3['upd_user_id'] = this.currentUser.id;
            farmObj[farmObj.length] = finalObj_3;
            finalObj_3['cr_date'] = new Date().getTime();
            finalObj_3['upd_date'] = new Date().getTime();
            idbApp.addStoreInfo(farmObj, 'farmerstmp');
            this.commonService.showToast('info', 'offline_sync_later', {});
            this.updateFarmerForm.markAsUntouched();
            this.updateFarmerForm.markAsPristine();
            this.renderer.selectRootElement('#farmerNameEdit').focus();
            if (this.updateFarmerModal) {
                this.updateFarmerModal.hide();
            }
            this.registerSyncEvent();
        }
    };
    FarmermanagementComponent.prototype.confirmModalFn = function (what) {
        if (what === 'Yes') {
            this.declineUpdateFarmer();
        }
        else {
            this.confirmModal = true;
        }
    };
    FarmermanagementComponent.prototype.confirmModalRemoveFn = function (what) {
        if (what === 'Yes') {
        }
    };
    FarmermanagementComponent.prototype.confirmModalRegistrationFn = function (what) {
        if (what === 'Yes') {
            this.declineRegisterFarmer();
        }
        else {
            this.confirmModalRegistration = true;
        }
    };
    FarmermanagementComponent.prototype.declineUpdateFarmerPrompt = function (form) {
        if (form.dirty) {
            this.confirmModal = false;
        }
        else {
            this.declineUpdateFarmer();
        }
    };
    // Email the farmer his / her own ID card
    // emailIdCard(farmer): void {
    //     this.farmerService.emailIdCard([farmer.id])
    //       .subscribe(result => {
    //         if (result.msg === 'fail' && result.data === 'noEmailId') {
    //           this.commonService.showToast('error', 'farmer_no_email', {});
    //         }
    //         // If successful, show the success message as a toast
    //         else if (result.msg === 'success' && result.data === 'emailSent') {
    //           this.commonService.showToast('success', 'farmer_id_email_sent', {});
    //         }
    //       },
    //         error => {           
    //           this.commonService.showToast('error', 'generic_error', {});
    //         })
    //   //}
    // }
    // Email multiple farmers his / her own ID card
    FarmermanagementComponent.prototype.emailIdCardBulk = function (farmerArray) {
        var _this = this;
        // Find the farmers that have a valid email ID
        var farmersToEmail = [];
        for (var i = 0; i < farmerArray.length; i++) {
            var farmer = farmerArray[i];
            if (farmer.email && farmer.email.length > 0) {
                farmersToEmail.push(farmer.id);
            }
        }
        // If no farmers have a valid email id, warn the user
        // if (farmersToEmail.length == 0) {
        //   this.commonService.showToast('warning', 'no_farmers_have_email_ids', {});
        // }
        // // If at least one farmer has a valid email id
        // else {
        // Call the email id card service and pass the array of farmers to be emailed
        this.farmerService.emailIdCardBulk(farmersToEmail)
            .subscribe(function (result) {
            // If the service returns a no email id message, notify the agent
            if (result.msg == 'fail' && result.data == 'noFarmersSelected') {
                _this.commonService.showToast('error', 'no_farmers_have_email_ids', {});
            }
            // If successful, show the success message as a toast
            else if (result.msg == 'success' && result.data == 'emailSentBulk') {
                _this.commonService.showToast('success', 'farmer_id_email_sent_bulk', {});
            }
        }, function (error) {
            _this.commonService.showToast('error', 'generic_error', {});
        });
    };
    // Toggle a particular farmer's selection status
    FarmermanagementComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedFarmers.push(id);
        }
        this.setTooltipText();
    };
    // Select / deselect all farmers in the array
    FarmermanagementComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length === this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var i = 0; i < this.farmers.length; i++) {
                this.selectedFarmers.push(this.farmers[i].id);
            }
        }
        this.setTooltipText();
    };
    // Print multiple ID cards - to be tested
    FarmermanagementComponent.prototype.printSelectedIdCards = function () {
        var _this = this;
        this.selectedPrintItems = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
        this.initiatePrintForAll();
    };
    // Email ID cards to all selected farmers
    FarmermanagementComponent.prototype.emailSelectedIdCards = function () {
        var _this = this;
        // Find the list of farmers that have been selected
        var farmersArrayToEmail = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
        // Call the bulk email function and pass this list
        this.emailIdCardBulk(farmersArrayToEmail);
    };
    // Set the farmer data to be printed
    FarmermanagementComponent.prototype.initiatePrint = function (farmer) {
        console.clear();
        this.farmerPrinting = Object.assign({}, farmer);
        this.farmerPrinting.photo_path = undefined;
        if (farmer.photo_path) {
            this.farmerPrinting.photo_path = "/" + this.locale + "/assets/uploads/" + farmer.photo_path;
        }
        else {
            if (farmer.sex === 3) {
                this.farmerPrinting.photo_path = '/' + this.locale + '/assets/images/farmer.png';
            }
            else if (farmer.sex === 4) {
                this.farmerPrinting.photo_path = '/' + this.locale + '/assets/images/female.png';
            }
        }
        // Find tht button that has the ngx-print tag & after a timeout, click it. Without a timeout the data is not set when the print fn is called
        var btn = document.getElementById('realPrintButton');
        setTimeout(function () { return btn.click(); }, 1000);
    };
    // When the slide is moved, patch the selected value in the form
    FarmermanagementComponent.prototype.setAgeValue = function (event) {
        this.registerFarmerForm.patchValue({
            age: this.farmerAgeSliderValue
        });
        this.registerFarmerForm.markAsDirty();
    };
    FarmermanagementComponent.prototype.setAgeValueUpdate = function (event) {
        this.updateFarmerForm.patchValue({
            age: this.farmerAgeSliderValueUpdate
        });
        this.updateFarmerForm.markAsDirty();
    };
    FarmermanagementComponent.prototype.exportSelectedFarmers = function () {
        var _this = this;
        var farmersList = $.extend(true, [], this.farmers);
        var selTemp2 = farmersList.filter(function (f) { return _this.selectedFarmers.includes(f.id); });
        var selTemp = [];
        for (var i = 0; i < selTemp2.length; i++) {
            selTemp[i] = selTemp2[i];
            if (selTemp[i]['sex'] === 3) {
                selTemp[i]['sex'] = 'Male';
            }
            else if (selTemp[i]['sex'] === 4) {
                selTemp[i]['sex'] = 'Female';
            }
        }
        var finalObj = {
            farmers: selTemp,
            projectName: this.project.name
        };
        if (this.selectedFarmers.length) {
            if (this.projectId != null && this.projectId !== 0) {
                this.projectService.exportFarmersProfilebyProjectId(this.projectId, finalObj)
                    .subscribe(function (result) {
                    if (result.status === 'success') {
                        var linkIndex = result.fileName.indexOf('assets');
                        var link = result.fileName.substring(linkIndex);
                        var clickLink = environment.apiBaseUrl + "/en/" + link;
                        var lastIndex = link.lastIndexOf("/");
                        link = link.substring(0, lastIndex);
                        var linkdownload = document.createElement('a');
                        linkdownload.setAttribute('type', 'hidden');
                        linkdownload.href = clickLink;
                        linkdownload.download = clickLink;
                        document.body.appendChild(linkdownload);
                        linkdownload.click();
                        linkdownload.remove();
                        _this.selectedFarmers = [];
                        _this.toastrService.success("Excel Downloaded");
                    }
                    else {
                        _this.toastrService.info("Information: " + result.msgText);
                    }
                });
            }
        }
        else {
            this.toastrService.info('Information: ' + "Select Farmers first");
        }
    };
    FarmermanagementComponent.prototype.printIDCards = function () {
        //
    };
    FarmermanagementComponent.prototype.printAll = function () {
        var _this = this;
        var doc = new jsPDF('p', 'pt');
        var text = "Farmer Management", xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
        doc.text(text, xOffset, 40);
        var selFarmersData = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
        var finalSelFarmerData = [];
        selFarmersData.forEach(function (snap) {
            var temp = [];
            temp.push(snap.farmer_code);
            temp.push(snap.name);
            temp.push(snap.tel_number);
            var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
            snap.cr_date = new Date(snap.cr_date);
            snap.cr_date = snap.cr_date.getDate() + "-" + months[snap.cr_date.getMonth()] + "-" + snap.cr_date.getFullYear();
            temp.push(snap.cr_date);
            finalSelFarmerData.push(temp);
        });
        var columns = ["Farmer Code", "Farmer Name", "Phone", "Registered Date"];
        var res = doc.autoTableHtmlToJson(document.getElementById("farmerTableAll"));
        doc.autoTable({
            head: [columns],
            body: finalSelFarmerData,
            margin: { top: 60 },
            theme: 'grid',
            bodyStyles: {
                columnWidth: 100,
                textColor: 20,
            }
        });
        doc.autoPrint({ variant: 'non-conform' });
        doc.output('dataurlnewwindow');
        doc.save("Farmer Management.pdf");
        this.selectedFarmers = [];
    };
    FarmermanagementComponent.prototype.goToFarmerDatasheet = function (farmer) {
        var url;
        if (farmer.id && farmer.id > 0) {
            url = "/farmerdatasheet/" + farmer.project_id + "/" + farmer.id;
        }
        else if (farmer.tmp_id && !navigator.onLine) {
            url = "/farmerdatasheet/" + farmer.project_id + "/" + farmer.tmp_id;
        }
        else if (farmer.tmp_id && navigator.onLine) {
            this.commonService.showToast('warning', 'please_wait_data_sync', { preventDuplicates: true });
            return;
        }
        else {
            url = '/error';
        }
        this.router.navigate([url]);
    };
    FarmermanagementComponent.prototype.setFarmerRegData = function () {
        if (this.farmers && this.farmers.length > 0) {
            for (var i = 0; i < this.farmers.length; i++) {
                if (this.farmers[i].farmer_reg) {
                    this.farmers[i] = tslib_1.__assign({}, this.farmers[i], this.farmers[i]['farmer_reg']);
                }
            }
        }
    };
    // DEEPIII-367 (on click of print id card, prompt user to select which printer they want to use)
    FarmermanagementComponent.prototype.promptPrinterSelection = function (template) {
        this.missingDataValuesDuringPrint = this.findMissingPrintingData();
        this.changeSelectedPrinter('zebra');
        this.selectPrinterAndPrint();
    };
    FarmermanagementComponent.prototype.declineSelectPrinterPrompt = function () {
        if (this.selectPrinterModal) {
            this.selectPrinterModal.hide();
        }
        this.selectedPrinterType = null;
    };
    FarmermanagementComponent.prototype.changeSelectedPrinter = function (printerType) {
        var _this = this;
        this.selectedPrinterType = printerType;
        var selectedFarmerData = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
    };
    FarmermanagementComponent.prototype.selectPrinterAndPrint = function () {
        // Call the print command with the selected printer type
        this.printSelectedIdCards();
        if (this.selectPrinterModal) {
            this.selectPrinterModal.hide();
        }
        this.selectedPrinterType = null;
    };
    // Send back an array of farmers with missing data points
    FarmermanagementComponent.prototype.findMissingPrintingData = function () {
        var _this = this;
        var selectedFarmerData = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
        // Mandatory data points: ID Card No
        var mandatoryDataFields = [];
        var missingData = [];
        for (var i = 0; i < selectedFarmerData.length; i++) {
            var missingDataForCurrentFarmer = [];
            for (var j = 0; j < mandatoryDataFields.length; j++) {
                if (!selectedFarmerData[i][mandatoryDataFields[j]]) {
                    missingDataForCurrentFarmer.push(mandatoryDataFields[j]);
                }
            }
            if (missingDataForCurrentFarmer.length > 0) {
                for (var i_1 = 0; i_1 < missingDataForCurrentFarmer.length; i_1++) {
                    if (missingDataForCurrentFarmer[i_1] === 'official_id_card_no') {
                        missingDataForCurrentFarmer[i_1] = 'ID Card No';
                    }
                }
                missingData.push({ farmer: selectedFarmerData[i]['name'], missingDataFields: missingDataForCurrentFarmer.join(", ") });
            }
        }
        return missingData;
    };
    FarmermanagementComponent.prototype.initiatePrintForAll = function () {
        // Find tht button that has the ngx-print tag & after a timeout, click it. Without a timeout the data is not set when the print fn is called
        if (this.selectedPrinterType === 'epson') {
            var btn_1 = document.getElementById('realPrintButtonEpson');
            setTimeout(function () { return btn_1.click(); }, 1000);
        }
        else if (this.selectedPrinterType === 'zebra') {
            var btn_2 = document.getElementById('realPrintButtonZebra');
            setTimeout(function () { return btn_2.click(); }, 1000);
        }
    };
    FarmermanagementComponent.prototype.sortFarmers = function () {
        this.farmers.sort(function (a, b) {
            if (a.cr_date < b.cr_date) {
                return 1;
            }
            if (a.cr_date > b.cr_date) {
                return -1;
            }
            return 0;
        });
    };
    FarmermanagementComponent.prototype.setIndicatorValues = function () {
        for (var i = 0; i < this.farmers.length; i++) {
            if (this.farmers[i].region_village && this.farmers[i].region_village != null) {
                this.farmers[i]['village_name'] = this.farmers[i].region_village.village_name;
            }
            else {
                this.farmers[i]['village_name'] = '';
            }
        }
    };
    FarmermanagementComponent.prototype.exportAllData = function (template) {
        if (this.canExportToExcel && this.canExportTraining) {
            this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else if (this.canExportToExcel) {
            this.exportFarmerData(this.selectedFarmers);
        }
        else if (this.canExportTraining) {
            this.exportTypes = this.exportTypes.filter(function (item) { return item.value != "Farmers Details"; });
            this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
    };
    FarmermanagementComponent.prototype.submitType = function () {
        if (this.selectedType == 'Farmers Details') {
            this.exportFarmerData(this.selectedFarmers);
        }
        if (this.selectedType == 'Training Details') {
            this.exportTrainingData('training');
        }
        if (this.selectedType == 'Certification Details') {
            this.exportTrainingData('certification');
        }
        this.closeTypePopup();
    };
    FarmermanagementComponent.prototype.closeTypePopup = function (fromUI) {
        if (fromUI) {
            if (this.selectedType) {
                this.confirmTypeModal = false;
            }
            else if (this.selectTypeModal) {
                this.selectTypeModal.hide();
                this.selectedType = undefined;
                this.confirmTypeModal = true;
            }
        }
        else if (this.selectTypeModal) {
            this.confirmTypeModal = true;
            this.selectTypeModal.hide();
            this.selectedType = undefined;
        }
    };
    FarmermanagementComponent.prototype.confirmTypeModalFn = function (decision) {
        if (decision == "Yes") {
            this.closeTypePopup();
            this.confirmTypeModal = true;
        }
        else {
            this.confirmTypeModal = true;
        }
    };
    FarmermanagementComponent.prototype.exportFarmerData = function (x) {
        var _this = this;
        this.spinner.show();
        var farmerdetail = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
        this.farmerService.exportData(this.projectId.toString(), farmerdetail)
            .subscribe(function (result) {
            var wb = XLSX.utils.book_new();
            var wsBooks = XLSX.utils.json_to_sheet(result.message);
            var wscols = [
                { wpx: 80 },
                { wpx: 80 },
                { wpx: 120 },
                { wpx: 300 },
                { wpx: 100 },
                { wpx: 80 },
                { wpx: 80 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 200 }
            ];
            wsBooks['!cols'] = wscols;
            var sheetName = "Farmer Sheet 1";
            XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
            var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            var excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            var blob = new Blob([excelBuffer], { type: fileType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + '-' + mm + '-' + yyyy;
            linkdownload.download = 'P2F-Roots_' + _this.farmerLabel + '_' + today1 + '.xlsx';
            linkdownload.style.display = 'none';
            document.body.appendChild(linkdownload);
            linkdownload.click();
            linkdownload.remove();
            // var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            // const blob = new Blob([result], {type: contentType});
            // const linkdownload = document.createElement('a');
            // linkdownload.href = URL.createObjectURL(blob);
            // const today = new Date();
            // const dd = String(today.getDate()).padStart(2, '0');
            // const mm = String(today.getMonth() + 1).padStart(2, '0');
            // const yyyy = today.getFullYear();
            // const today1 = dd + '-' + mm + '-' + yyyy;
            // linkdownload.download = 'P2F-Roots_' + this.farmerLabel + '_' + today1 + '.xlsx';
            // linkdownload.style.display = 'none';
            // document.body.appendChild(linkdownload);
            // linkdownload.click();
            _this.selectedFarmers = [];
            _this.spinner.hide();
        });
    };
    FarmermanagementComponent.prototype.exportTrainingData = function (type) {
        var _this = this;
        var farmerdetail = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
        var farmerArray = [];
        for (var _i = 0, farmerdetail_1 = farmerdetail; _i < farmerdetail_1.length; _i++) {
            var item = farmerdetail_1[_i];
            farmerArray.push(item.id);
        }
        var obj = {
            "farmerIds": farmerArray
        };
        this.trainingService.trainingFarmerManagementExportToExcel(this.projectId.toString(), obj, type)
            .subscribe(function (result) {
            var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            var blob = new Blob([result], { type: contentType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + '-' + mm + '-' + yyyy;
            _this.translate.get(type + "s").subscribe(function (val) {
                linkdownload.download = 'P2F-Roots_' + _this.farmerLabel + ' ' + val + '_ExcelExport_' + today1 + '.xlsx';
                linkdownload.style.display = 'none';
                document.body.appendChild(linkdownload);
                linkdownload.click();
                _this.selectedFarmers = [];
            }, function (err) { return console.log('err', err); });
        }, function (error) {
            _this.commonService.showToast('warning', 'no_data_found_selected_farmers', {});
            _this.selectedFarmers = [];
        });
    };
    FarmermanagementComponent.prototype.setVCMapping = function () {
        if (this.farmers && this.farmers.length > 0) {
            for (var i = 0; i < this.farmers.length; i++) {
                if (this.farmers[i].vc_farmer_mappings && this.farmers[i].vc_farmer_mappings.length > 0) {
                    var vcNames = '';
                    for (var j = 0; j < this.farmers[i].vc_farmer_mappings.length; j++) {
                        if (this.farmers[i].vc_farmer_mappings[j].status == 'Active') {
                            vcNames = this.farmers[i].vc_farmer_mappings[j].users.name + ', ' + vcNames;
                        }
                    }
                    vcNames = vcNames.substring(0, vcNames.length - 2);
                    this.farmers[i].village_collectors = vcNames;
                }
                else {
                    this.farmers[i].village_collectors = '';
                }
            }
        }
    };
    FarmermanagementComponent.prototype.loadFarmerDataIntoCache = function (projectId, gte, lt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(projectId && projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
                            ])
                                .then(function (result) {
                                console.log('result', result);
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    FarmermanagementComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length == this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length == 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length == 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    FarmermanagementComponent.prototype.showMissingTrainingDetails = function (id, template) {
        for (var _i = 0, _a = Object.entries(this.missingTrainings); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (key == id) {
                this.farmerMissingTrainings = value;
            }
        }
        for (var _c = 0, _d = this.farmerMissingTrainings; _c < _d.length; _c++) {
            var item = _d[_c];
            var date1 = new Date();
            var date2 = new Date(item.due_date);
            var timeInMilisec = date1.getTime() - date2.getTime();
            var daysBetweenDates = Math.floor(timeInMilisec / (1000 * 60 * 60 * 24));
            item.overdue = daysBetweenDates;
        }
        this.trainingDetailsModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    FarmermanagementComponent.prototype.closeMissingTrainingPopup = function () {
        if (this.trainingDetailsModal) {
            this.trainingDetailsModal.hide();
            this.farmerMissingTrainings = [];
        }
    };
    FarmermanagementComponent.prototype.exportSelectedConsents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmerdetail, farmerArray, _i, farmerdetail_2, item, obj, requests, _loop_6, _a, _b, farmer, btn_3;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.consentTemplate) return [3 /*break*/, 4];
                        farmerdetail = this.farmers.filter(function (item) { return _this.selectedFarmers.indexOf(item.id) > -1; });
                        farmerArray = [];
                        for (_i = 0, farmerdetail_2 = farmerdetail; _i < farmerdetail_2.length; _i++) {
                            item = farmerdetail_2[_i];
                            if (!item.consentPending) {
                                farmerArray.push(item.id);
                            }
                        }
                        if (!(farmerArray.length > 0)) return [3 /*break*/, 2];
                        this.spinner.show();
                        obj = {
                            "farmerIds": farmerArray
                        };
                        return [4 /*yield*/, Promise.all([
                                this.dataConsentService.getFarmerConsentForFarmers(this.projectId, obj).toPromise()
                            ])];
                    case 1:
                        requests = _c.sent();
                        if (requests[0].msg == 'success') {
                            this.consentFarmersData = requests[0].data;
                            if (this.consentFarmersData) {
                                _loop_6 = function (farmer) {
                                    var farmerName = farmerdetail.find(function (item) { return item.id == farmer.farmer_id; });
                                    if (farmerName) {
                                        farmer.name = farmerName.name;
                                    }
                                };
                                for (_a = 0, _b = this.consentFarmersData; _a < _b.length; _a++) {
                                    farmer = _b[_a];
                                    _loop_6(farmer);
                                }
                                btn_3 = document.getElementById('consentPrintButtonEpson');
                                setTimeout(function () { return btn_3.click(); }, 1000);
                            }
                            else {
                                this.commonService.showToast('warning', 'no_consent_found_this_language', { preventDuplicates: true });
                            }
                        }
                        this.selectedFarmers = [];
                        this.spinner.hide();
                        return [3 /*break*/, 3];
                    case 2:
                        this.selectedFarmers = [];
                        this.commonService.showToast('warning', 'consent_not_available_selected_farmers', { preventDuplicates: true });
                        _c.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        this.commonService.showToast('warning', 'consent_template_not_available', { preventDuplicates: true });
                        this.selectedFarmers = [];
                        _c.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    //added by Kiran for famer consent
    FarmermanagementComponent.prototype.getFarmerConsents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _loop_7, this_5, _i, _a, _b, key, value;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.dataConsentService.getFarmerConsents(this.projectId).toPromise()
                        ])];
                    case 1:
                        requests = _c.sent();
                        if (requests[0].msg == 'success') {
                            this.farmerConsent = requests[0].data;
                        }
                        _loop_7 = function (key, value) {
                            var findFarmer = this_5.farmers.find(function (item) { return item.id == key; });
                            if (findFarmer) {
                                findFarmer.consentPending = !value["is_consent_valid"];
                                findFarmer.valid_upto = value["valid_upto"];
                            }
                        };
                        this_5 = this;
                        for (_i = 0, _a = Object.entries(this.farmerConsent); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], value = _b[1];
                            _loop_7(key, value);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // Delete farmer btn click function
    FarmermanagementComponent.prototype.discontinueFarmer = function (template, farmer) {
        this.discontinuingFarmer = farmer;
        this.discontinueFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    // Redirect the program based on user intput on delete modal
    FarmermanagementComponent.prototype.confirmModalDiscontinueFarmer = function (what) {
        if (what === 'yes') {
            var discontinueAction_1 = this.discontinuingFarmer.is_discontinued ? 0 : 1;
            if (navigator.onLine) {
                this.confirmDiscontinueFarmer(this.discontinuingFarmer, discontinueAction_1);
            }
            else {
                // Push offline deleted farmer data in browser's IndexedDB
                var farmerObj_4 = this.discontinuingFarmer;
                var finalObj_4 = {};
                Object.keys(farmerObj_4).forEach(function (key) {
                    if (typeof farmerObj_4[key] === 'object' && farmerObj_4[key] != null) {
                        if (farmerObj_4[key].length && farmerObj_4[key].length > 0) {
                            finalObj_4[key] = farmerObj_4[key];
                        }
                    }
                    else {
                        finalObj_4[key] = farmerObj_4[key];
                    }
                });
                var farmObj_2 = new Array();
                if (farmerObj_4.id < 1) {
                    var rawFarmerId = farmerObj_4.tmp_id;
                    var index = rawFarmerId.indexOf('_');
                    var rawFarmerIdEdited = rawFarmerId.substr(0, index);
                    finalObj_4['tmp_id'] = rawFarmerIdEdited + "_Tab00";
                    finalObj_4['cr_user_id'] = this.currentUser.id;
                    finalObj_4['cr_date'] = new Date().getTime();
                }
                else {
                    finalObj_4['tmp_id'] = farmerObj_4.farmer_code + "_Tab00";
                }
                finalObj_4['savetab'] = 'Tab00';
                finalObj_4['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                finalObj_4['is_discontinued'] = discontinueAction_1;
                finalObj_4['upd_user_id'] = this.currentUser.id;
                farmObj_2[farmObj_2.length] = finalObj_4;
                finalObj_4['upd_date'] = new Date().getTime();
                idbApp.addStoreInfo(farmObj_2, 'farmerstmp');
                this.farmers.map(function (item) {
                    if (item.id == farmObj_2[0].id) {
                        item.is_discontinued = discontinueAction_1;
                    }
                });
                this.discontinueFarmerModal.hide();
                this.commonService.showToast('info', 'offline_sync_later', {});
            }
        }
        else {
            this.discontinuingFarmer = undefined;
            if (this.discontinueFarmerModal) {
                this.discontinueFarmerModal.hide();
            }
        }
    };
    //  Call farmer delete API when user is online
    FarmermanagementComponent.prototype.confirmDiscontinueFarmer = function (farmer, discontinueAction) {
        var _this = this;
        if (this.discontinueFarmerModal) {
            this.discontinueFarmerModal.hide();
        }
        this.spinner.show();
        this.farmerService.discontinueFarmer(this.projectId, farmer.id, discontinueAction)
            .subscribe(function (result) {
            _this.discontinuingFarmer = undefined;
            if (result.msg === 'success') {
                _this.spinner.hide();
                if (discontinueAction == 1) {
                    farmer.is_discontinued = true;
                    _this.commonService.showToast('success', 'farmer_discontinued', {});
                }
                else {
                    farmer.is_discontinued = false;
                    _this.commonService.showToast('success', 'farmer_continued', {});
                }
            }
            else {
                _this.commonService.showToast('error', 'error_farmer_deleted', {});
            }
        });
    };
    //filter farmers array based on search text
    FarmermanagementComponent.prototype.filterFarmers = function () {
        var _this = this;
        this.farmers = this.filteredfarmers;
        if (this.searchText.length > 0) {
            var filterArr = this.farmers.filter(function (item) {
                if ((item.farmer_code && item.farmer_code.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.name && item.name.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.cr_date && item.cr_date.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.official_id && item.official_id.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.age && item.age == _this.searchText) ||
                    (item.village_name && item.village_name.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1)) {
                    return item;
                }
            });
            this.farmers = filterArr;
        }
    };
    return FarmermanagementComponent;
}());
export { FarmermanagementComponent };
