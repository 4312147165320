import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { TrainingService } from '../../services/training.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
var TrainingManagementComponent = /** @class */ (function () {
    function TrainingManagementComponent(route, router, spinner, userService, modalService, projectService, trainingService, translate) {
        this.route = route;
        this.router = router;
        this.spinner = spinner;
        this.userService = userService;
        this.modalService = modalService;
        this.projectService = projectService;
        this.trainingService = trainingService;
        this.translate = translate;
        this.searchText = '';
        this.searchTextCerti = '';
        this.page = 1;
        this.itemsPerPage = 10;
        this.currentUser = this.userService.getCurrentUser();
        this.confirmTypeModal = true;
        this.isAscending = true;
        this.isAscendingCerti = true;
        this.isAscendingName = true;
        this.isAscendingType = true;
        this.isAscendingTrainerName = true;
        this.isAscendingCertifiedBy = true;
        this.isAscendingDueDate = true;
        this.isAscendingTrainingDate = true;
        this.isAscendingNameCerti = true;
        this.isAscendingTrainingDateCerti = true;
        this.canExportToExcel = false;
    }
    TrainingManagementComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, canExportToExcel, canExportToExcel;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.projectId = +this.route.snapshot.params['id'];
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.projectService.getProjectDashboardData(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0]);
                        if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
                            this.UPM = this.project.ProjectMapping.find(function (item) { return item.users_id === _this.currentUser.id; });
                        }
                        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
                        }
                        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
                        }
                        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
                            canExportToExcel = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP" && item.user_type_id === _this.currentUser.role.id); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                        }
                        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
                            canExportToExcel = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "TRAIN_EXP"); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                        }
                        this.getTrainingCertifcationList();
                        return [2 /*return*/];
                }
            });
        });
    };
    TrainingManagementComponent.prototype.getTrainingCertifcationList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.trainingService.get_training_recordings(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.trainings = request[0]['data'].filter(function (item) { return item.is_training; });
                            this.certifications = request[0]['data'].filter(function (item) { return !item.is_training; });
                        }
                        // console.log('trainings', this.trainings);
                        // for(const item of this.certifications){
                        //   if(item.certified_by == 1) {
                        //     item.certified_by = "Firmenich";
                        //   } else if(item.certified_by == 2) {
                        //     item.certified_by = this.project.Supplier.name;
                        //   } else {
                        //     item.certified_by = '';
                        //   }
                        // }
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    TrainingManagementComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    TrainingManagementComponent.prototype.addTraingCertificate = function (template) {
        this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    TrainingManagementComponent.prototype.editTrainingCertification = function (id, t) {
        var type;
        if (t == "Training") {
            type = "training";
        }
        else {
            type = "certification";
        }
        var url = "/training-management/edit/" + type + "/" + this.projectId + "/" + id;
        this.router.navigate([url]);
    };
    TrainingManagementComponent.prototype.viewTrainingCertification = function (id, t) {
        var type;
        if (t == "Training") {
            type = "training";
        }
        else {
            type = "certification";
        }
        var url = "/training-management/edit/" + type + "/" + this.projectId + "/" + id;
        this.router.navigate([url], { queryParams: { isView: true } });
    };
    TrainingManagementComponent.prototype.submitType = function () {
        this.exportToExcel(this.selectedType);
        this.closeTypePopup();
    };
    TrainingManagementComponent.prototype.closeTypePopup = function (fromUI) {
        if (fromUI) {
            if (this.selectedType) {
                this.confirmTypeModal = false;
            }
            else if (this.selectTypeModal) {
                this.selectTypeModal.hide();
                this.selectedType = undefined;
                this.confirmTypeModal = true;
            }
        }
        else if (this.selectTypeModal) {
            this.confirmTypeModal = true;
            this.selectTypeModal.hide();
            this.selectedType = undefined;
        }
    };
    TrainingManagementComponent.prototype.confirmModalFn = function (decision) {
        if (decision == "Yes") {
            this.closeTypePopup();
            this.confirmTypeModal = true;
        }
        else {
            this.confirmTypeModal = true;
        }
    };
    TrainingManagementComponent.prototype.exportToExcel = function (type) {
        var _this = this;
        this.spinner.show();
        this.trainingService.trainingManagementExportToExcel(this.projectId, type).subscribe(function (result) {
            //console.log('result', result);
            var wb = XLSX.utils.book_new();
            var wsBooks = XLSX.utils.json_to_sheet(result.message);
            var wscols = [
                { wpx: 200 },
                { wpx: 130 },
                { wpx: 130 },
                { wpx: 200 }
            ];
            wsBooks['!cols'] = wscols;
            var sheetName = "Sheet 1";
            XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
            var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            var excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            var blob = new Blob([excelBuffer], { type: fileType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + '-' + mm + '-' + yyyy;
            _this.translate.get('training_management').subscribe(function (val) {
                linkdownload.download = "P2F-Roots_" + val + "_ExcelExport_" + today1 + ".xlsx";
                linkdownload.style.display = 'none';
                document.body.appendChild(linkdownload);
                linkdownload.click();
                linkdownload.remove();
            }, function (err) { return console.log('err', err); });
            // var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            // const blob = new Blob([result], {type: contentType});
            // const linkdownload = document.createElement('a');
            // linkdownload.href = URL.createObjectURL(blob);
            // var today = new Date();
            // var dd = String(today.getDate()).padStart(2, '0');
            // var mm = String(today.getMonth() + 1).padStart(2, '0');
            // var yyyy = today.getFullYear();
            // var today1 = `${dd}-${mm}-${yyyy}`;
            // this.translate.get('training_management').subscribe((val) => {
            //   linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
            //   linkdownload.style.display = 'none';
            //   document.body.appendChild(linkdownload);
            //   linkdownload.click();
            // }, err => console.log('err', err));
            _this.spinner.hide();
        }, function (err) {
            console.log('err', err);
            _this.spinner.hide();
        });
    };
    TrainingManagementComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['training_certification_name', 'trainer_name', 'training_date'];
        var finalObj = {};
        if (this.trainings === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    TrainingManagementComponent.prototype.getGridFiltersCerti = function () {
        var _this = this;
        var gridColums = ['training_certification_name', 'certified_by', 'certification_date'];
        var finalObj = {};
        if (this.certifications === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchTextCerti;
            });
        }
        return finalObj;
    };
    TrainingManagementComponent.prototype.onKeydownHandler = function (event) {
        this.closeTypePopup();
    };
    TrainingManagementComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    TrainingManagementComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        this.isAscendingName = sortBy !== 'training_certification_name';
        this.isAscendingTrainerName = sortBy !== 'trainer_name';
        this.isAscendingTrainingDate = sortBy !== 'training_date';
        // Sort of new fields added
        if (sortBy === 'training_certification_name' || sortBy === 'trainer_name') {
            this.trainings.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'training_date') {
            this.trainings.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    TrainingManagementComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        this.isAscendingName = sortBy === 'training_certification_name';
        this.isAscendingTrainerName = sortBy === 'trainer_name';
        this.isAscendingTrainingDate = sortBy === 'training_date';
        // Sort of new fields added
        if (sortBy === 'training_certification_name' || sortBy === 'trainer_name') {
            this.trainings.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'training_date') {
            this.trainings.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    TrainingManagementComponent.prototype.sortByCerti = function (sortOn) {
        this.isAscendingCerti ? this.ascendicCerti(sortOn) : this.descendicCerti(sortOn);
    };
    TrainingManagementComponent.prototype.ascendicCerti = function (sortBy) {
        this.isAscendingCerti = false;
        this.isAscendingNameCerti = sortBy !== 'training_certification_name';
        this.isAscendingCertifiedBy = sortBy !== 'certified_by';
        this.isAscendingTrainingDateCerti = sortBy !== 'certification_date';
        // Sort of new fields added
        if (sortBy === 'training_certification_name' || sortBy === 'certified_by') {
            this.certifications.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'certification_date') {
            this.certifications.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    TrainingManagementComponent.prototype.descendicCerti = function (sortBy) {
        this.isAscendingCerti = true;
        this.isAscendingNameCerti = sortBy === 'training_certification_name';
        this.isAscendingCertifiedBy = sortBy === 'certified_by';
        this.isAscendingTrainingDateCerti = sortBy === 'certification_date';
        // Sort of new fields added
        if (sortBy === 'training_certification_name' || sortBy === 'certified_by') {
            this.certifications.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
        else if (sortBy === 'certification_date') {
            this.certifications.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
    };
    return TrainingManagementComponent;
}());
export { TrainingManagementComponent };
