import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var VisitService = /** @class */ (function () {
    function VisitService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.visitURL = this.appConfigService.getUrl(this.appConfigService.VISIT);
        this.visitTypeURL = this.appConfigService.getUrl(this.appConfigService.VISIT_TYPES);
    }
    VisitService.prototype.getAllVisits = function (projectId) {
        return this.http.get(this.visitURL + "/allvisits/" + projectId);
    };
    VisitService.prototype.getRegionVillages = function (projectId) {
        return this.http.get(this.visitURL + "/getRegionVillages/" + projectId);
    };
    VisitService.prototype.getAllVisitTypes = function () {
        return this.http.get(this.visitTypeURL + "/visit_type_id");
    };
    VisitService.prototype.saveUpdateVisit = function (data, edit, projectId) {
        return edit ? this.http.put(this.visitURL + "/updateVisit/" + projectId, data) :
            this.http.post(this.visitURL + "/addVisit/" + projectId, data);
    };
    VisitService.prototype.visitManagementExportToExcel = function (projectID, type) {
        return this.http.post(this.visitURL + "/exportVisits/" + projectID + "/" + type, {});
    };
    VisitService.prototype.getAllVisitPurposes = function () {
        return this.http.get(this.visitTypeURL + "/visit_purpose_id");
    };
    VisitService.ngInjectableDef = i0.defineInjectable({ factory: function VisitService_Factory() { return new VisitService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: VisitService, providedIn: "root" });
    return VisitService;
}());
export { VisitService };
